<template>
  <div class="flex mt-2 flex-row space-x-6 justify-center">
    <Sidebar :menu="{
      'home': {'href': '/', 'title': 'Home'},
      'dash': {'href': '/dashboard', 'title': 'Dashboard'},
      'car': {'href': '/dashboard/career', 'title': 'Career'},
      'mapwise': {'href': '/dashboard/mapwise', 'title': 'Mapwise'},
      'profileCard': {'href': '/profile-card', 'title': 'Profile Card'},
      'spaces': {'href': '/spaces', 'title': 'Spaces'}
    }" v-if="!isHidden" />
    <div class="flex-none w-3/5 items-start px-3 rounded-box">
      <FullScreenLoader v-if="isLoading" />
      <div class="flex flex-row justify-between md:justify-end space-x-4 mt-6 items-end">
        <a class="md:hidden" @click="isHidden = !isHidden">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16"/>
          </svg>
        </a>
      </div>
      <div class="overflow-x-auto flex-wrap w-full mt-6" style="height: 95vh">
        <div class="px-3 py-3 bg-base-200 rounded-box">
          <p class="text-lg leading-10 text-secondary uppercase mb-5 border-b border-base-300">Averages</p>
          <div class="flex flex-wrap flex-col md:flex-row justify-between">
            <div class="shadow stats mb-3 md:mb-0" v-for="(v, k) in getOverviewData">
              <div class="stat px-8 hover:bg-primary">
                <div class="stat-title">{{ k }}</div>
                <div class="stat-value">{{ v }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex mt-3 flex-none justify-center">
          <div class="tabs">
            <a class="tab tab-bordered uppercase" v-for="(tab, ind) in tabs"
               :class="{'tab-active': currentTab === ind}"
               @click="currentTab = ind">{{ tab }}</a>
          </div>
        </div>
        <div class="bg-base-200 rounded-box mt-5" v-show="currentTab === 0">
          <LineChart
              :chart-data="impactChart"
              :options="impactChartOption"
              class="px-4 py-4"></LineChart>
        </div>
        <div class="bg-base-200 rounded-box mt-5" v-show="currentTab === 1">
          <RadarChart
              :chart-data="styleChart"
              :options="radarChartOption"
              class="px-4 py-4"></RadarChart>
        </div>
        <div class="bg-base-200 rounded-box mt-5" v-show="currentTab === 2">
          <BarChart
              :chart-data="agentChart"
              :options="agentChartOption"
              :height='200'
              class="px-4 py-4"></BarChart>
        </div>
        <div class="bg-base-200 rounded-box mt-5 overflow-y-auto" v-show="currentTab === 3">
          <div class="flex flex-row flex-wrap mx-4 my-4 px-4 py-4 items-center justify-center">
            <div class="flex flex-col items-center space-x-2 space-y-2" v-for="(v,k) in dataDump['killweapons']">
              <img :src="weaponImg(k)" style="max-height: 256px; max-width: 96px; width: 60%; height: 40%;">
              <div class="badge badge-info">{{ v }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-64 text-lg border-l-2 border-base-300 artboard hidden md:block">

    </div>
  </div>
</template>

<script>
import axios from "axios";
import {computed, onMounted, ref} from "vue";
import store from "@/store";
import {Chart, registerables} from "chart.js";
import {BarChart, LineChart, RadarChart} from 'vue-chart-3';
import Sidebar from "@/components/Sidebar";
import FullScreenLoader from "@/components/FullScreenLoader";
import {useRouter} from "vue-router";

Chart.register(...registerables);

export default {
  name: 'Historical',
  components: {
    Sidebar,
    LineChart,
    BarChart,
    RadarChart,
    FullScreenLoader
  },
  setup() {
    let csrf_token = '';
    const dataDump = ref({});
    const currentTab = ref(0);
    const tabs = ref(['Impact', 'Style', 'Agents', 'Weapons']);
    const isLoading = ref(false);
    const isHidden = ref(false);
    const router = useRouter();

    async function initial() {
      isLoading.value = true;
      await axios.get(
          '/valorant/csrf-token/'
      ).then(
          response => {
            csrf_token = response.data
          }
      ).catch(
          error => {
            if (error.response.status === 401) {
              router.push('/login')
            }
          }
      )
      await axios.post(
          '/valorant/historicalstats/v1/',
          {
            headers: {'X-CSRFToken': csrf_token},
            'puuid': store.state.user.uuid
          }
      ).then(
          response => {
            dataDump.value = response.data
            isLoading.value = false;
          }
      )
    }

    // Utility

    function weaponImg(val) {
      return `https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/weapons/${val}.png`
    }

    onMounted(() => {
      initial()
    });


    // Computed

    const getOverviewData = computed(() => {
      return {
        Kills: dataDump.value['killsavg'],
        Deaths: dataDump.value['deathsavg'],
        Score: dataDump.value['scoreavg'],
        KD: dataDump.value['kdavg'],
        Damage: dataDump.value['dmgavg'],
        Headshot: `${dataDump.value['hsavg']}%`
      }
    });

    const agentChart = computed(() => {
      return {
        labels: dataDump.value.agentlist,
        datasets: [
          {
            type: 'bar',
            label: 'Kills',
            fill: true,
            data: dataDump.value.agentkills,
            backgroundColor: [
              'rgba(255, 0, 0, 0.2)',
            ],
            borderColor: [
              'red',
            ],
            borderWidth: 2,
          },
          {
            type: 'bar',
            label: 'Deaths',
            fill: true,
            data: dataDump.value.agentdeaths,
            backgroundColor: [
              '#46EAD0',
            ],
            borderColor: [
              'rgba(3, 198, 252, 0)',
            ],
            borderWidth: 2,
          }
        ]
      }
    });

    const agentChartOption = computed(() => {
      return {
        responsive: true,
        indexAxis: 'x',
        maintainAspectRatio: false,
        scales: {
          yAxes: {
            beginAtZero: true,
            grid: {
              display: false,
            },
            title: {
              display: true,
              text: 'Kills & Deaths'
            },
            drawBorder: false,
            categoryPercentage: 1,
            barPercentage: 0.1,
          },
          xAxes: {
            grid: {
              display: false,
            },
            drawBorder: false,
            categoryPercentage: 1,
            barPercentage: 0.1,
            title: {
              display: true,
              text: 'Rounds',
              font: {
                family: 'Poppins',
                size: 12
              }
            }
          }
        },
      }
    });

    const impactChart = computed(() => {
      return {
        labels: dataDump.value.matchwisex,
        datasets: [
          {
            label: 'KD',
            fill: true,
            data: dataDump.value.kdy,
            yAxisID: 'y1',
            backgroundColor: [
              'rgba(255, 0, 0, 0.2)',
            ],
            borderColor: [
              'red',
            ],
            borderWidth: 2,
          },
          {
            label: 'Headshot %',
            fill: true,
            data: dataDump.value.hsy,
            yAxisID: 'y2',
            backgroundColor: [
              '#46EAD0',
            ],
            borderColor: [
              'rgba(3, 198, 252, 0)',
            ],
            borderWidth: 2,
          }
        ]
      }
    });

    const impactChartOption = computed(() => {
      return {
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        maintainAspectRatio: false,
        stacked: false,
        scales: {
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            title: {
              display: true,
              text: 'KD'
            },
            grid: {
              display: false,
            },
          },
          y2: {
            type: 'linear',
            display: true,
            position: 'left',
            title: {
              display: true,
              text: 'Headshot %'
            },
            grid: {
              display: false,
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
          },
          xAxes: {
            grid: {
              display: true,
            },
            drawBorder: false,
            title: {
              display: true,
              text: 'Rounds',
              font: {
                family: 'Poppins',
                size: 12
              }
            }
          }
        },
      }
    });

    const styleChart = computed(() => {
      return {
        labels: ['Early Round', 'Mid Round', 'Late Round'],
        datasets: [{
          label: 'Controller',
          data: [
            dataDump.value.killtyperemap_con_e,
            dataDump.value.killtyperemap_con_m,
            dataDump.value.killtyperemap_con_l
          ],
          backgroundColor: [
            'rgba(161, 74, 0, 0.2)',
          ],
          borderColor: [
            'rgba(161, 74, 0, 1)',
          ],
          borderWidth: 1,
          borderRadius: 10,
        },
          {
            label: 'Duelist',
            data: [
              dataDump.value.killtyperemap_due_e,
              dataDump.value.killtyperemap_due_m,
              dataDump.value.killtyperemap_due_l
            ],
            backgroundColor: [
              'rgba(3, 198, 252, 0.2)',
            ],
            borderColor: [
              'rgba(3, 198, 252, 1)',
            ],
            borderWidth: 1,
            borderRadius: 10,
          },
          {
            label: 'Sentinel',
            data: [
              dataDump.value.killtyperemap_sen_e,
              dataDump.value.killtyperemap_sen_m,
              dataDump.value.killtyperemap_sen_l
            ],
            backgroundColor: [
              'rgba(0, 127, 8, 0.2)',
            ],
            borderColor: [
              'rgba(0, 127, 8, 1)',
            ],
            borderWidth: 1,
            borderRadius: 10,
          },
          {
            label: 'Initiator',
            data: [
              dataDump.value.killtyperemap_ini_e,
              dataDump.value.killtyperemap_ini_m,
              dataDump.value.killtyperemap_ini_l
            ],
            backgroundColor: [
              'rgba(131, 0, 72, 0.2)',
            ],
            borderColor: [
              'rgba(131, 0, 72, 1)',
            ],
            borderWidth: 1,
            borderRadius: 10,
          }
        ]
      }
    });

    const radarChartOption = computed(() => {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          r: {
            angleLines: {
              display: true
            },
            ticks: {
              stepSize: 25,
              color: 'rgba(255, 255, 255, 1)',
              backdropColor: '#19192E',
              font: {
                size: 15
              },
            },
            pointLabels: {
              font: {
                size: 15
              },
              color: 'white'
            }
          }
        },
        plugins: {
          legend: {
            display: true,
            align: 'end',
            labels: {
              usePointStyle: true,
              pointStyle: 'circle',
              color: 'white'
            }
          },
        },
      }
    });

    return {
      dataDump,
      currentTab,
      tabs,
      getOverviewData,
      agentChart,
      agentChartOption,
      impactChart,
      impactChartOption,
      styleChart,
      radarChartOption,
      weaponImg,
      isLoading,
      isHidden
    }
  }
}
</script>