<template>
  <Navbar/>

  <div class="flex flex-col items-center">
    <p class="text-xl text-secondary mt-5 mb-2">To connect your Riot account, please enter the following details</p>
    <p class="text-sm text-gray-700">
      Connect your account only. By connecting your account, you accept our terms of service and policies.
      Breach of these could result in instant removal of your account.
    </p>
    <ul class="steps steps-vertical md:steps-horizontal mt-5 mb-5">
      <li class="step step-primary">Sign Up</li>
      <li class="step step-primary">Connect Riot Account</li>
      <li class="step">Let's Go</li>
    </ul>
    <div v-if="!fetchingData" class="form-control">
      <form @submit.prevent="submitForm">
        <label class="input-group mb-4 mt-4">
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M10 2a1 1 0 00-1 1v1a1 1 0 002 0V3a1 1 0 00-1-1zM4 4h3a3 3 0 006 0h3a2 2 0 012 2v9a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2zm2.5 7a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm2.45 4a2.5 2.5 0 10-4.9 0h4.9zM12 9a1 1 0 100 2h3a1 1 0 100-2h-3zm-1 4a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1z"
                  clip-rule="evenodd"/>
          </svg>
        </span>
          <input type="text" placeholder="Riot Id" v-model="riotid" class="input input-bordered">
        </label>
        <label class="input-group mb-4 mt-4">
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M9.243 3.03a1 1 0 01.727 1.213L9.53 6h2.94l.56-2.243a1 1 0 111.94.486L14.53 6H17a1 1 0 110 2h-2.97l-1 4H15a1 1 0 110 2h-2.47l-.56 2.242a1 1 0 11-1.94-.485L10.47 14H7.53l-.56 2.242a1 1 0 11-1.94-.485L5.47 14H3a1 1 0 110-2h2.97l1-4H5a1 1 0 110-2h2.47l.56-2.243a1 1 0 011.213-.727zM9.03 8l-1 4h2.938l1-4H9.031z"
                  clip-rule="evenodd"/>
          </svg>
        </span>
          <input type="text" placeholder="Riot Tag" v-model="riottag" class="input input-bordered">
        </label>
        <label class="input-group mb-4 mt-4">
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
          </svg>
        </span>
          <select class="select select-bordered" v-model="region">
            <option>EU</option>
            <option>NA</option>
            <option>AP</option>
            <option>KR</option>
          </select>
        </label>
        <div class="mb-3 mt-3">
          <button class="btn btn-secondary">Let's Go</button>
        </div>
      </form>
    </div>
    <div v-else>
      <Loader />
    </div>
  </div>

</template>

<script>
import Navbar from "@/components/Navbar";
import {ref} from "vue";
import axios from "axios";
import {useRouter} from "vue-router";
import Loader from "@/components/Loader";
import {useStore} from "vuex";
export default {
  name: 'RiotInfo',
  components: {Loader, Navbar},
  setup() {
    const region = ref('');
    const riotid = ref('');
    const riottag = ref('');
    const fetchingData = ref(false);
    const router = useRouter();
    const store = useStore();
    let csrf_token;

    async function submitForm() {
      fetchingData.value = true;
      await axios.get(
          '/valorant/csrf-token/'
      ).then(
          response => {
            csrf_token = response.data
          }
      )

      await axios.post(
          '/valorant/accountupdate/v1/',
          {
            headers: {
              'X-CSRFToken': csrf_token
            },
            'riotid': riotid.value,
            'riottag': riottag.value,
            'region': region.value
          }
      ).then(
          response => {
            if (response.status === 200){

              localStorage.setItem('task_id', response.data);
              localStorage.setItem('fetchingComplete', 'false');
              localStorage.setItem('skh', 'true');
            } else {
              // console.log('errrorrrrrr')
              alert('Some error occurred')
            }
          }
      )
      await axios.get(
          '/valorant/csrf-token/'
      ).then(
          response => {
            csrf_token = response.data
          }
      )

      await axios.post(
          '/account/in/referral-gate/',
          {
            headers: {
              'X-CSRFToken': csrf_token
            },
            'referral_code': localStorage.getItem('ref')
          }
      ).then(
          response => {
            if (response.data !== 'Code Applied'){
              if (localStorage.getItem('ref') !== null) {
                alert('Some error occurred while applying referral code. We are looking into it. It will not affect your experience')
              }
            }
          }
      )
      await axios
          .get("/account/in/me/")
          .then(response => {
            store.commit('setUser', {
              'username': response.data.username,
              'id': response.data.id,
              'uuid': response.data.uuid
            })
            localStorage.setItem('username', response.data.username)
            localStorage.setItem('userid', response.data.id)
            localStorage.setItem('uuid', response.data.uuid)

            fetchingData.value = false;
            router.push('/dashboard')
          })
          .catch(error => {
            console.log(JSON.stringify(error))
          })
    }

    return {
      region,
      riottag,
      riotid,
      submitForm,
      fetchingData
    }

  }
}
</script>