<template>
  <div class="flex mt-2 flex-row space-x-6 justify-center">
    <Sidebar :menu="{
      'home': {'href': '/', 'title': 'Home'},
      'dash': {'href': '/dashboard', 'title': 'Dashboard'},
      'car': {'href': '/dashboard/career', 'title': 'Career'},
      'mapwise': {'href': '/dashboard/mapwise', 'title': 'Mapwise'},
      'profileCard': {'href': '/profile-card', 'title': 'Profile Card'},
      'spaces': {'href': '/spaces', 'title': 'Spaces'}
    }" v-if="!isHidden" />
    <div class="flex-none w-3/5 items-start px-3 rounded-box">
      <FullScreenLoader v-if="isLoading"></FullScreenLoader>
      <div class="flex flex-row justify-between md:justify-end space-x-4 mt-6 items-end">
        <a class="md:hidden" @click="isHidden = !isHidden">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16"/>
          </svg>
        </a>
      </div>
      <div class="overflow-x-auto flex-wrap w-full mt-6" style="height: 95vh">
        <div class="px-3 py-3 bg-base-200 rounded-box">
          <p class="text-lg leading-10 text-secondary uppercase mb-5 border-b border-base-300">Performance Averages</p>
          <div class="flex flex-wrap flex-col md:flex-row justify-between">
            <div class="shadow stats mb-3 md:mb-0" v-for="(v, k) in getOverviewData">
              <div class="stat px-8 hover:bg-primary">
                <div class="stat-title">{{ k }}</div>
                <div class="stat-value">{{ v }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex mt-3 flex-none justify-center">
          <div class="tabs">
            <a class="tab tab-bordered uppercase" v-for="(tab, ind) in tabs"
               :class="{'tab-active': currentTab === ind}"
               @click="currentTab = ind">{{ tab }}</a>
          </div>
        </div>
        <div class="bg-base-200 rounded-box mt-5" v-show="currentTab === 0">
          <MapwiseKD
              :chart-type="getKillData.chartType"
              :point-data="getKillData.pointData"
              :mapname="getKillData.mapname"
          ></MapwiseKD>
        </div>
        <div class="bg-base-200 rounded-box mt-5" v-show="currentTab === 1">
          <MapwiseKD
              :chart-type="getDeathData.chartType"
              :point-data="getDeathData.pointData"
              :mapname="getDeathData.mapname"
          ></MapwiseKD>
        </div>
        <div class="flex space-x-4 mt-5" v-show="currentTab === 2">
          <div class="flex-none bg-base-200 rounded-box w-3/5">
            <DoughnutChart
                :chart-data="agentChart"
                class="px-8 py-8" :width='200' :height='100'></DoughnutChart>
          </div>
          <div class="flex flex-col bg-base-200 rounded-box items-center justify-center w-full">
            <img :src="agentImg(dataDump['mostkillagent'])" class="bg-red-500 mask mask-pentagon">
            <p class="text-xl py-5 px-5 badge badge-info badge-lg mt-5">Most Kills</p>
          </div>
        </div>
        <div class="flex flex-row bg-base-200 rounded-box mt-5" v-show="currentTab === 3">
          <div class="card bg-base-100 mx-2 my-2 lg:card-side w-2/5 card-bordered">
            <div class="card-body">
              <h2 class="card-title">Most Used</h2>
              <img :src="weaponImg(getWeaponData.mostUsed)" class="mt-3 w-3/5">
              <p class="text-lg px-2 py-2 mt-5 badge badge-lg badge-info">{{getWeaponData.mostUsedCount}}</p>
            </div>
          </div>
          <div class="card bg-base-100 mx-2 my-2 lg:card-side w-2/5 card-bordered">
            <div class="card-body">
              <h2 class="card-title">Least Used</h2>
              <img :src="weaponImg(getWeaponData.leastUsed)" class="w-3/5">
              <p class="text-lg px-2 py-2 mt-5 badge badge-lg badge-info">{{getWeaponData.leastUsedCount}}</p>
            </div>
          </div>
          <div class="card bg-base-100 mx-2 my-2 lg:card-side w-2/5 card-bordered">
            <div class="card-body">
              <h2 class="card-title">Most Effective</h2>
              <img :src="weaponImg(getWeaponData.mostKill)" class="w-3/5 mt-3 ">
              <p class="text-lg px-2 py-2 mt-5 badge badge-lg badge-info">{{getWeaponData.mostKillCount}}</p>
            </div>
          </div>
          <div class="card bg-base-100 mx-2 my-2 lg:card-side w-2/5 card-bordered">
            <div class="card-body">
              <h2 class="card-title">Least Effective</h2>
              <img :src="weaponImg(getWeaponData.leastKill)" class="w-3/5">
              <p class="text-lg px-2 py-2 mt-5 badge badge-lg badge-info">{{getWeaponData.leastKillCount}}</p>
            </div>
          </div>
        </div>
        <div class="bg-base-200 rounded-box mt-5" v-show="currentTab === 4">
          <LineChart
              :chart-data="impactChart"
              :options="impactChartOption"
              class="px-4 py-4"></LineChart>
        </div>
      </div>
    </div>
    <div class="w-64 text-lg border-l-2 border-base-300 artboard hidden md:block">
      <div class="bg-base-100 ml-4 artboard border border-base-100 shadow-md rounded-box px-4 py-4 items-center">
        <select class="select select-primary mx-2" name="maps" ref="mapFilter">
          <option>Ascent</option>
          <option>Bind</option>
          <option>Haven</option>
          <option>Breeze</option>
          <option>Icebox</option>
          <option>Split</option>
          <option>Fracture</option>
        </select>

        <div v-if="isPremium">
          <select class="bg-base-100 mt-5 mx-2 px-3 py-5 border border-primary rounded-box" name="agents" multiple
                  v-model="agentFilter">
            <option>Astra</option>
            <option>Breach</option>
            <option>Brimstone</option>
            <option>Chamber</option>
            <option>Cypher</option>
            <option>Jett</option>
            <option>KAY/O</option>
            <option>Killjoy</option>
            <option>Omen</option>
            <option>Phoenix</option>
            <option>Raze</option>
            <option>Reyna</option>
            <option>Sage</option>
            <option>Skye</option>
            <option>Sova</option>
            <option>Viper</option>
            <option>Yoru</option>

          </select>

          <label class="label text-sm font-light mx-2 mt-5">Start Date</label>
          <input ref="startDate" type="date" class="bg-base-200 mx-2 w-4/5 rounded-box px-3 py-2 mt-1 mb-4 input">
        </div>
        <div v-else>
          <div class="alert alert-warning text-sm mt-4">
            You get Map filter and analysis for your latest 6 matches for free. Try Premium for free for 7 days.
          </div>
          <select disabled class="bg-base-100 mt-5 mx-2 px-3 py-5 border border-primary rounded-box" name="agents" multiple
                  v-model="agentFilter">
            <option>Astra</option>
            <option>Breach</option>
            <option>Brimstone</option>
            <option>Chamber</option>
            <option>Cypher</option>
            <option>Jett</option>
            <option>KAY/O</option>
            <option>Killjoy</option>
            <option>Neon</option>
            <option>Omen</option>
            <option>Phoenix</option>
            <option>Raze</option>
            <option>Reyna</option>
            <option>Sage</option>
            <option>Skye</option>
            <option>Sova</option>
            <option>Viper</option>
            <option>Yoru</option>

          </select>

          <label class="label text-sm font-light mx-2 mt-5">Start Date</label>
          <input disabled ref="startDate" type="date" class="bg-base-200 mx-2 w-4/5 rounded-box px-3 py-2 mt-1 mb-4 input">
        </div>
        <p class="text-sm mx-1 px-2 py-2 mb-5"><span class="font-bold">{{getTotal.totalMatches}}</span> Matches found</p>
        <button class="btn btn-outline mx-2" @click="filteredData">Apply</button>
        <button class="btn btn-outline" @click="resetData">Reset</button>
        <div class="alert alert-error mt-5 mx-2" v-if="getIsAvailable.isAvailable === 'no' || sneakybeaky">
          <label v-if="sneakybeaky">Thats a sneaky move. Contact Us on discord. We might have something for you</label>
          <label v-else>No data found for the selection</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {computed, onMounted, onUpdated, ref} from "vue";
import MapwiseKD from "@/components/MapwiseKD";
import store from "@/store";
import Sidebar from "@/components/Sidebar";
import {DoughnutChart, LineChart} from "vue-chart-3";
import FullScreenLoader from "@/components/FullScreenLoader";
import {useRouter} from "vue-router";

export default {
  name: 'Mapwise',
  components: {
    FullScreenLoader,
    MapwiseKD,
    Sidebar,
    DoughnutChart,
    LineChart,
  },
  setup() {
    let csrf_token = '';
    const isLoading = ref(false);
    const dataDump = ref({});
    const isPremium = ref(false);
    const isHidden = ref(false);

    const sneakybeaky = ref(false);

    // Filter Vars
    const mapFilter = ref('Ascent');
    const agentFilter = ref([]);
    const startDate = ref('');

    const currentTab = ref(0);
    const tabs = ref(['Killmap', 'Deathmap', 'Agents', 'Weapons', 'Impact'])
    const router = useRouter();

    async function initial() {
      isLoading.value = true;
      await axios.get('/valorant/csrf-token/')
          .then(response => {
            csrf_token = response.data
          }).catch(
              error => {
                if (error.response.status === 401) {
                  router.push('/login')
                }
              }
          )

      await axios.post(
          '/valorant/career-filtered/v1/',
          {
            headers: {'X-CSRFToken': csrf_token},
            'puuid': store.state.user.uuid,
            'mapname': mapFilter.value.value,
            'dateFilter': startDate.value.value,
            'agentFilter': agentFilter.value
          }
      )
          .then(response => {
            dataDump.value = response.data
            isLoading.value = false;
          })
      await axios.get(
          '/account/in/p-checks/'
      ).then(
          response => {
            if (response.data === 'Premium'){
              isPremium.value = true;
            }
          }
      )
    }


    function filteredData() {
      if (!isPremium.value) {
        if (agentFilter.value.length !== 0 || startDate.value['value'] !== '') {
          sneakybeaky.value = true;
        }
      }
      if (sneakybeaky.value !== true) {
        initial();
      }
    }

    function resetData() {
      mapFilter.value.value = 'Ascent';
      agentFilter.value = [];
      startDate.value.value = '';
      initial();
    }

    // Utility

    function agentImg(val) {
      return `https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/agents/${val}.png`
    }

    function weaponImg(val) {
      return `https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/weapons/${val}.png`
    }


    onMounted(() => {
      initial()
    });


    const getKillData = computed(() => {
      return {
        chartType: 'kill',
        pointData: dataDump.value.kills_location,
        mapname: dataDump.value.mapname,
      }
    });

    const getDeathData = computed(() => {
      return {
        chartType: 'death',
        pointData: dataDump.value.deaths_location,
        mapname: dataDump.value.mapname,
      }
    });

    const impactChart = computed(() => {
      return {
        labels: dataDump.value.kdmatchwisex,
        datasets: [
          {
            type: 'line',
            label: 'KD',
            fill: true,
            data: dataDump.value.kdmatchwisey,
            yAxisID: 'y1',
            backgroundColor: [
              '#46EAD0',
            ],
            borderColor: [
              '#46EAD0',
            ],
            borderWidth: 2,
          },
          {
            type: 'bar',
            label: 'Damage',
            fill: false,
            data: dataDump.value.dmgmatchwisey,
            yAxisID: 'y2',
            backgroundColor: [
              'red',
            ],
            borderColor: [
              'rgba(255, 0, 0, 0.2)',
            ],
            borderWidth: 2,
          }
        ]
      }
    });

    const impactChartOption = computed(() => {
      return {
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        maintainAspectRatio: false,
        stacked: false,
        scales: {
          y1: {
            type: 'linear',
            display: true,
            title: {
              display: true,
              text: 'KD'
            },
            position: 'right',
            grid: {
              display: false,
            },
          },
          y2: {
            type: 'linear',
            display: true,
            title: {
              display: true,
              text: 'Damage'
            },
            position: 'left',
            grid: {
              display: false,
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
          },
          xAxes: {
            grid: {
              display: true,
            },
            drawBorder: false,
            title: {
              display: true,
              text: 'Matches',
              font: {
                family: 'Poppins',
                size: 12
              }
            }
          }
        },
      }
    });

    const getOverviewData = computed(() => {
      return {
        Kills: dataDump.value['kills'],
        Deaths: dataDump.value['deaths'],
        Headshot: `${dataDump.value['hs']}%`,
        Aces: dataDump.value['aces'],
        QuadKills: dataDump.value['quadkills'],
        Trifecta: dataDump.value['trifecta'],
      }
    });

    const agentChart = computed(() => {
      return {
        labels: dataDump.value['agents'],
        datasets: [
          {
            label: 'Pick Percentage',
            data: dataDump.value['pickpct'],
            backgroundColor: [
              '#EF4444',
              '#C27171',
              '#DB8000',
              '#804A00',
              '#88A300',
              '#DBFF29',
              '#86FF29',
              '#429900',
              '#00FF44',
              '#009E2A',
              '#008C9E',
              '#00E1FF',
              '#0015FF',
              '#5400A3',
              '#BF00FF',
              '#FF00A6',
              '#FF0040',
              '#5E0D0D'
            ],
          }
        ]
      }
    });

    const getWeaponData = computed(() => {
      return {
        mostUsed: dataDump.value['mostusedweapon'],
        leastUsed: dataDump.value['leastusedweapon'],
        mostKill: dataDump.value['mostkillweapon'],
        leastKill: dataDump.value['leastkillweapon'],
        mostUsedCount: dataDump.value['mostusedweaponcount'],
        leastUsedCount: dataDump.value['leastusedweaponcount'],
        mostKillCount: dataDump.value['mostkillweaponcount'],
        leastKillCount: dataDump.value['leastkillweaponcount']
      }
    });

    const getIsAvailable = computed(() => {
      return {
        isAvailable: dataDump.value['isavailable']
      }
    });

    const getTotal = computed(() => {
      return {
        totalMatches: dataDump.value['totalmatches']
      }
    });

    return {
      getIsAvailable,
      getTotal,
      dataDump,
      getKillData,
      getDeathData,
      impactChart,
      impactChartOption,
      getOverviewData,
      agentChart,
      getWeaponData,
      mapFilter,
      agentFilter,
      startDate,
      filteredData,
      resetData,
      tabs,
      currentTab,
      agentImg,
      weaponImg,
      isLoading,
      isPremium,
      sneakybeaky,
      isHidden
    }
  }
}
</script>