<template>
  <div class="home">
    <Navbar/>
<!--    <div class="hero h-screen">-->
<!--      <div class="flex flex-col md:text-center hero-content">-->
<!--        <h1 class="mb-10 text-4xl md:text-7xl tracking-wide leading-10 font-thin">-->
<!--          BUILT EXCLUSIVELY FOR <span class="text-secondary font-light">PROS.</span>-->
<!--        </h1>-->
<!--        <div class="space-x-6 mb-3">-->
<!--          <router-link v-if="$store.state.isAuthenticated" to="/dashboard" class="btn btn-primary">Continue to Dashboard</router-link>-->
<!--          <router-link v-else :to=signupString class="btn btn-primary">Absolutely, I am a Pro</router-link>-->
<!--          <a href="#features" class="btn btn-outline">What is this about?</a>-->
<!--        </div>-->
<!--        <div class="animate-bounce mt-10 mb-10">-->
<!--          <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" viewBox="0 0 20 20" fill="currentColor">-->
<!--            <path fill-rule="evenodd"-->
<!--                  d="M15.707 4.293a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 011.414-1.414L10 8.586l4.293-4.293a1 1 0 011.414 0zm0 6a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 111.414-1.414L10 14.586l4.293-4.293a1 1 0 011.414 0z"-->
<!--                  clip-rule="evenodd"/>-->
<!--          </svg>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="hero flex flex-col h-screen items-center" id="features">
      <h1 class="text-center text-4xl md:text-7xl tracking-wide leading-10 font-semibold mt-5">PLAY. ANALYZE. <span class="animate-pulse text-primary">REPEAT </span></h1>
      <div class="flex flex-col mb-4 mt-48 items-center justify-between md:flex-row mt-4">
        <div class="card lg:card-side">
          <div class="card-body">
            <ul class="leading-loose tracking-wide">
              <li class="inline-flex items-center text-xl">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-3" viewBox="0 0 20 20" fill="#13ce66">
                  <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"/>
                </svg>
                Kills & Death Spots
              </li>
              <br>
              <li class="inline-flex items-center text-xl">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-3" viewBox="0 0 20 20" fill="#13ce66">
                  <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"/>
                </svg>
                Strong agent
              </li>
              <br>
              <li class="inline-flex items-center text-xl">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-3" viewBox="0 0 20 20" fill="#13ce66">
                  <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"/>
                </svg>
                Game Overview
              </li>
              <br>
              <li class="inline-flex items-center text-xl">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-3" viewBox="0 0 20 20" fill="#13ce66">
                  <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"/>
                </svg>
                Career Analysis
              </li>
              <br>
              <li class="inline-flex items-center text-xl">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-3" viewBox="0 0 20 20" fill="#13ce66">
                  <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"/>
                </svg>
                Customizable Dashboard
              </li>
              <br>
              <li class="inline-flex items-center text-xl">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-3" viewBox="0 0 20 20" fill="#13ce66">
                  <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"/>
                </svg>
                Mapwise Analysis
              </li>
            </ul>
          </div>
          <div class="w-full md:w-6/12 justify-center bg-base-100 l-img opacity-70 transition ease-in duration-700 hover:opacity-100">
            <figure>
              <img class="rounded-box"
                   src="https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/generic/dashboard.png">
            </figure>
          </div>
        </div>
      </div>
      <div class="space-x-6 mt-20 mb-12">
        <router-link :to=signupString class="btn btn-primary">Let's go</router-link>
        <a href="#demo" class="btn btn-outline">Show me how</a>
      </div>
      <div class="animate-bounce mt-5 mb-10">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M15.707 4.293a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 011.414-1.414L10 8.586l4.293-4.293a1 1 0 011.414 0zm0 6a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 111.414-1.414L10 14.586l4.293-4.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"/>
          </svg>
        </div>
    </div>
    <div class="flex flex-col mb-12" id="demo">
      <div class="flex flex-col space-y-4 md:space-y-0 md:space-x-4 mb-4 items-center justify-between md:flex-row mt-4">
        <div class="card md:card-side">
          <div class="card-body">
            <div class="card-title">
            </div>
            <video controls>
              <source src="https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/generic/MatchwiseAnalysis.mp4" type="video/mp4">
            </video>
          </div>
        </div>

      </div>
      <router-link v-if="$store.state.isAuthenticated" to="/dashboard" class="btn btn-primary w-8/12 md:w-3/12 mt-5 self-center">Continue to Dashboard</router-link>
      <router-link v-else :to=signupString class="btn btn-primary w-5/12 md:w-3/12 mt-5 self-center">Sign Up Now</router-link>
    </div>


    <Footer/>
  </div>
</template>

<style scoped>

.l-img{
  border: #171618 solid 1px;
  border-radius: 50px;
  filter: drop-shadow(0 0 5px #3C167A);
}
</style>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Footer from "@/components/Footer";
import {useRoute} from "vue-router";
import {ref} from "vue";

export default {
  name: 'StreamerLanding',
  components: {
    Footer,
    Navbar
  },
  setup(){
    const route = useRoute();
    const streamerRef = route.query.ref;
    const signupString = ref('/signup');
    const streamersStore = {
      'EGGWICK': 'EGGWICK',
      'NICSHARROCK': 'NICSHARROCK'
    };

    if (Object.keys(streamersStore).includes(streamerRef)) {
      signupString.value = `/signup?ref=${streamersStore[streamerRef]}`
    }

    return {
      signupString
    }
  }
}
</script>