import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueMeta from "vue-meta";
import './assets/tailwind.css'
import "@/assets/styles/main.css";
import './registerServiceWorker'

axios.defaults.baseURL = 'https://testapi.swiftskill.gg' //'http://127.0.0.1:8000'


createApp(App).use(store).use(router, axios, VueMeta).mount('#app')
