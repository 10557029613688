<template>
  <div id="video-area" class="flex flex-col items-center"></div>
</template>

<script>

import {onMounted, onUpdated, ref} from "vue";
import {TwitchEmbed, TwitchEmbedLayout, TwitchPlayerEvent} from "twitch-player";

export default {
  name: 'TwitchPlay',
  props: {
    channelName: String,
  },
  setup(props){
    const embed = ref(null);

    onMounted(() => {
      embed.value = new TwitchEmbed('video-area', {
        width: 800,
        height: 400,
        channel: props.channelName,
        layout: TwitchEmbedLayout.VIDEO
      })

      embed.value.play()
    })

    onUpdated(() => {
      embed.value.setChannel(props.channelName)

      embed.value.play()
    })
  }
}
</script>