<template>
  <div v-if="premApply()" class="flex flex-row items-center justify-between" id="heatmapParent">
    <div class="flex flex-col items-center">
      <canvas ref="heatmapcnv" id="heatmap"></canvas>
      <div class="space-x-4">
        <button class="btn btn-primary" @click="resetState">Reset</button>
        <button class="btn btn-primary" @click="changeBG">Toggle Callout</button>
      </div>
    </div>
    <div class="bg-base-300 rounded-box px-4 py-4">
      <p class="text-sm text-gray-400">Click on a point to view more details</p>
      <div ref="summaryP" class="flex flex-col"></div>
    </div>
  </div>
  <div v-else class="flex flex-row items-center justify-between">
    <div class="card artboard">
      <div class="badge badge-lg badge-warning px-10 py-32">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd"
                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                clip-rule="evenodd"/>
        </svg>
        <span class="px-4">This is a premium only feature</span>
        <router-link to="/pricing" class="btn btn-info">Try free for 7 days</router-link>
      </div>
    </div>
  </div>
</template>

<script>

import {onUpdated, ref} from "vue";

export default {
  name: 'Heatmap',
  props: {
    pointData: Object
  },
  setup(props) {
    const cdnurl = 'https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets';
    const csize = 400;
    const prad = 5;
    const kdetails = ref([]);
    const heatmapcnv = ref(null);

    const summaryP = ref(null);

    let callouts = false;
    const leechesCount = ref(0);

    function initial() {
      try {
        const heatmapcanvas = heatmapcnv.value;
        const heatmapCtx = heatmapcanvas.getContext('2d');

        heatmapcanvas.width = csize;
        heatmapcanvas.height = csize;
        heatmapcanvas.style.backgroundImage = 'url(' + cdnurl + `/maps-general/${props.pointData['mapname']}.png)`;
        heatmapcanvas.style.backgroundRepeat = "no-repeat";
        heatmapcanvas.style.backgroundSize = "100%";

        defaultChart(heatmapCtx, heatmapcanvas, null);
        addleeches(kdetails, heatmapCtx, heatmapcanvas);
      } catch (err) {
        console.log(err)
      }
    }

    function premApply() {
      return props.pointData['chartType'] === 'kill' ? ref(true) : props.pointData['premiumSub'];
    }

    function resetState() {
      const heatmapcanvas = heatmapcnv.value;
      const heatmapCtx = heatmapcanvas.getContext('2d');

      summaryBox(
          'delete',
          {}
      );

      defaultChart(heatmapCtx, heatmapcanvas, null);
    }

    function weaponImg(val) {
      return val.includes("Ultimate") || val.includes("Ability")
          ?
          `https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/agent-abilities/${val}`
          :
          `https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/weapons/${val}`
    }

    function inRange(ex, ey, x, y) {
      return Math.hypot(ex - x, ey - y)
    }

    function processtime(timeval) {
      let timer
      let time = Math.round(timeval)
      if (time < 60) {
        if (time < 10) {
          timer = '0:0' + String(time)
        } else {
          timer = '0:' + String(time)
        }
      } else if (time === 60) {
        timer = '1:00'
      } else if (time > 60 && time < 120) {
        if (time - 60 < 10) {
          timer = '1:0' + String(time - 60)
        } else {
          timer = '1:' + String(time - 60)
        }
      } else if (time === 120) {
        timer = '2:00'
      } else {
        if (time - 120 < 10) {
          timer = '2:0' + String(time - 120)
        } else {
          timer = '2:' + String(time - 120)
        }
      }
      return timer
    }

    function summaryBox(flag, obj) {
      let summaryParent = summaryP.value;
      if (flag === 'create') {
        let docP = document.createElement('div');
        docP.classList.add('summaryElements', 'flex', 'flex-row', 'items-center', 'space-x-4', 'py-3');

        let docRound = document.createElement('div');
        docRound.classList.add('summaryElements', 'text-lg', 'text-secondary', 'badge', 'badge-accent', 'py-4');
        docRound.textContent = obj['roundNum'];
        docP.append(docRound)

        let docTime = document.createElement('div');
        docTime.classList.add('summaryElements', 'text-lg', 'py-4', 'badge', 'badge-info');
        docTime.setAttribute('title', 'Round Timer');
        docTime.textContent = obj['time'];
        docP.append(docTime)

        summaryParent.append(docP);

        let docImg = document.createElement('img');
        docImg.classList.add('summaryElements', 'py-3');
        docImg.style.maxWidth = "96px";
        docImg.style.maxHeight = "256px";
        docImg.style.width = "50%";
        docImg.style.height = "50%";
        docImg.src = obj['weaponName'];
        summaryParent.append(docImg);
      } else {
        while (summaryParent.lastChild) {
          summaryParent.removeChild(summaryParent.lastChild);
        }
      }
    }

    function addleeches(arr, ctx, canvas) {
      if (leechesCount.value < 1) {
        arr.value.forEach(function (obj) {
          let x = obj['x'] / 1024 * csize;
          let y = csize - obj['y'] / 1024 * csize;
          canvas.addEventListener("click", function (event) {
            if (inRange(event.offsetX, event.offsetY, x, y) <= prad) {
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              let vertices = [];
              const chartType = props.pointData['chartType'];
              summaryBox(
                  'create',
                  {
                    time: processtime(obj['time']),
                    roundNum: `Round ${obj['roundnum']}`,
                    weaponName: weaponImg(obj['weapon_id'])
                  }
              );

              if (chartType === 'kill') {
                drawOnCanvas_agent(ctx, canvas, obj['vloc'][0], obj['vloc'][1], obj['victimagent'], 'victim');
                drawOnCanvas_agent(ctx, canvas, obj['x'], obj['y'], obj['killeragent'], 'killer');
                drawLineEnemies(ctx, obj['x'], obj['y'], obj['vloc'][0], obj['vloc'][1], vertices);
                animateIt(ctx, vertices);
              } else {
                drawOnCanvas_agent(ctx, canvas, obj['kloc'][0], obj['kloc'][1], obj['killeragent'], 'killer');
                drawOnCanvas_agent(ctx, canvas, obj['x'], obj['y'], obj['victimagent'], 'victim');
                drawLineEnemies(ctx, obj['kloc'][0], obj['kloc'][1], obj['x'], obj['y'], vertices);
                animateIt(ctx, vertices);
              }
            }
          })
        })
      }
      leechesCount.value += 1;
    }

    function defaultChart(ctx, canvas, roundnum) {
      const chartType = props.pointData['chartType'];
      const colorMap = {
        'kill': "#00FF00",
        'death': "#DC143C"
      }
      let pData = props.pointData['pointData'];

      if (roundnum == null) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        for (const [key, value] of Object.entries(pData)) {
          for (let obj of value) {
            drawOnCanvas(ctx, canvas, obj['x'], obj['y'], colorMap[chartType])
            kdetails.value.push(obj)
          }
        }
        return null
      }
    }

    function drawOnCanvas(ctx, canvas, xCord, yCord, color) {
      let x = xCord / 1024 * csize;
      let y = yCord / 1024 * csize;
      let point = new Path2D();

      ctx.fillStyle = color;
      point.arc(x, csize - y, prad, 0, 2 * Math.PI);
      ctx.shadowBlur = 50;
      ctx.shadowColor = color;
      ctx.fill(point);
    }

    function drawOnCanvas_agent(ctx, canvas, xCord, yCord, agent, kind) {
      let x = xCord / 1024 * csize;
      let y = yCord / 1024 * csize;

      const colormap = {
        "killer": "#00FF00",
        "victim": "#DC143C"
      }

      let nImg = new Image();
      nImg.onload = function () {
        let point = new Path2D();
        ctx.fillStyle = colormap[kind];
        point.arc(x - 5, csize - y - 2, 15, 0, 2 * Math.PI);
        ctx.shadowBlur = 50;
        ctx.shadowColor = colormap[kind];
        ctx.fill(point);
        ctx.drawImage(nImg, x - 15, csize - y - 15, 25, 25);

      }
      nImg.src = cdnurl + `/agents/${agent}.png`;
    }

    function drawLineEnemies(ctx, x1, y1, x2, y2, vertices) {
      ctx.setLineDash([8, 12])
      ctx.beginPath();
      ctx.moveTo(x1 / 1024 * csize, csize - y1 / 1024 * csize);
      ctx.lineTo(x2 / 1024 * csize, csize - y2 / 1024 * csize);
      ctx.lineWidth = 3;
      ctx.strokeStyle = "#FFFAF0"
      ctx.shadowColor = "black";
      ctx.shadowBlur = 60;
      ctx.stroke();

      vertices.push({
        x: x1 / 1024 * csize,
        y: csize - y1 / 1024 * csize
      });
      vertices.push({
        x: x2 / 1024 * csize,
        y: csize - y2 / 1024 * csize
      });
    }

    function calcWaypoints(vertices) {
      let waypoints = [];
      for (let i = 1; i < vertices.length; i++) {
        let pt0 = vertices[i - 1];
        let pt1 = vertices[i];
        let dx = pt1.x - pt0.x;
        let dy = pt1.y - pt0.y;
        for (let j = 0; j < 100; j++) {
          let x = pt0.x + dx * j / 100;
          let y = pt0.y + dy * j / 100;
          waypoints.push({
            x: x,
            y: y
          });
        }
      }
      return (waypoints);
    }

    function animateIt(ctx, vertices) {
      function animate() {
        if (t < points.length - 1) {
          requestAnimationFrame(animate);
        }
        ctx.beginPath();
        ctx.moveTo(points[t - 1].x, points[t - 1].y);
        ctx.lineTo(points[t].x, points[t].y);
        ctx.fill();
        ctx.stroke();
        t++;
      }

      let t = 1;
      ctx.lineWidth = 3;
      ctx.strokeStyle = "#FFFAF0";

      let points = calcWaypoints(vertices);
      animate(points);
    }

    function changeBG() {
      const heatmapcanvas = heatmapcnv.value;
      if (callouts === false) {
        callouts = true;
        heatmapcanvas.style.backgroundImage = `url('https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/maps-callouts/${
            props.pointData['mapname']
        }.png')`
      } else {
        callouts = false;
        heatmapcanvas.style.backgroundImage = `url('https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/maps-general/${
            props.pointData['mapname']
        }.png')`
      }
    }

    onUpdated(() => {
      initial()
    });

    return {
      summaryP,
      heatmapcnv,
      resetState,
      changeBG,
      premApply
    }
  }
}
</script>