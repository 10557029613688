<template>
  <div class="flex-none w-full items-center px-3">
    <div class="overflow-x-auto overflow-y-auto w-full mt-6" style="height: 85vh">
      <table class="table w-full">
        <thead>
        <tr>
          <th class="bg-base-100  hidden md:block">
            Compare
          </th>
          <th class="bg-base-100 ">
            Map
          </th>
          <th class="bg-base-100 ">
            Score
          </th>
          <th class="bg-base-100  hidden md:block">
            Date
          </th>
        </tr>
        </thead>
        <tbody >
        <tr class="animate-pulse" v-for="i in 10">
          <td class="rounded-l">
            <div class="bg-gray-900 rounded-badge w-1/5">&nbsp;</div>
          </td>
          <td>
            <div class="bg-gray-900 rounded-badge w-3/5">&nbsp;</div>
          </td>
          <td>
            <div class="bg-gray-900 rounded-badge w-2/5">&nbsp;</div>
          </td>
          <td class="rounded-r">
            <div class="bg-gray-900 rounded-badge w-3/5">&nbsp;</div>
          </td>
        </tr>

        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardLoader'
}
</script>