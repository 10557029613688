<template>
  <div class="shadow stats mb-3 md:mb-0" v-for="(v, k) in dataObj">
    <div v-if="k!=='Headshot'" class="stat hover:bg-primary">
      <div class="stat-title">{{k}}</div>
      <div class="stat-value">{{v}}</div>
    </div>
    <div v-else class="stat hover:bg-primary">
      <div class="stat-title">Headshot</div>
      <div class="stat-value">{{dummy}}</div>
    </div>
  </div>
</template>

<script>
import {onUpdated, ref} from "vue";

export default {
  name: 'OverviewCard',
  props: {
    dataDump: Object
  },
  setup(props){
    let dataObj = ref(null);
    let dummy = ref(null);

    onUpdated(() => {
      dataObj.value = props.dataDump;
      let a = dataObj.value['Headshot'][0]
      dummy.value = a
      // dataObj.value.Headshot = a
      // console.log(dummy.value)
    })

    return {
      dataObj,
      dummy
    }
  }
}
</script>