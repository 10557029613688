<template>
  <div class="flex items-center justify-center">
    <YoutubeVue3 ref="youtube" :videoid="play.video_id" :loop="play.loop" :width="800" :height="400"/>
  </div>
</template>

<script>
import { YoutubeVue3 } from 'youtube-vue3'
import {onUpdated, ref} from "vue";

export default {
  name: 'YouTubePlay',
  props: {
    videoID: String
  },
  components: {
    YoutubeVue3
  },
  setup(props){
    const play = ref({ video_id:"kg_9r5frBp8", loop:1 });

    if (props.videoID !== '') {
      play.value['video_id'] = props.videoID;
    }
    onUpdated(() => {
      play.value['video_id'] = props.videoID;
    })

    return {
      play,
    }
  },

}
</script>