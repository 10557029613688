<template>
  <Navbar />

  <div class="flex flex-col items-center">
    <p class="text-xl">You have been successfully logged out. Please <router-link to="/login" class="text-secondary">click here</router-link> to login again</p>
  </div>
</template>

<script>

import Navbar from "@/components/Navbar";
export default {
  name: 'LogoutSuccess',
  components: {Navbar},
}
</script>