<template>
  <Navbar />
  <div class="flex items-center flex-col">
    <div>
      <div class="form-control">
        <p class="text-lg text-start mt-4 mb-4 leading-loose tracking-wide text-info">Log In</p>
        <form @submit.prevent="submitForm">
          <label class="input-group mb-4 mt-4">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"/>
              </svg>
            </span>
            <input type="text" placeholder="Username" v-model="username" class="input input-bordered">
          </label>
          <label class="input-group mb-4 mt-4">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                      d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z"
                      clip-rule="evenodd"/>
              </svg>
            </span>
            <input type="password" placeholder="Password" v-model="password" class="input input-bordered">
          </label>

          <div class="mb-3 alert alert-error" v-if="errors.length" role="alert">
            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
          </div>
          <div class="mb-3">
            <button class="button btn btn-success">Log in</button>
          </div>
          <div class="mb-3">
            <p class="text-sm">Forgotten your password? <router-link to="/password-reset" class="text-secondary">Reset here</router-link> </p>
          </div>
          <div class="mb-3">
            <p class="text-sm">Not yet signed up? <router-link to="/signup" class="text-info">Sign up now!</router-link> </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Navbar from "@/components/Navbar";

export default {
  name: 'Login',
  components: {Navbar},
  data() {
    return {
      username: '',
      password: '',
      errors: []
    }
  },
  methods: {
    async submitForm(e) {
      axios.defaults.headers.common["Authorization"] = ""
      localStorage.removeItem("token")
      const formData = {
        username: this.username,
        password: this.password
      }
      await axios
          .post("/accounts/token/login/", formData)
          .then(response => {
            const token = response.data.auth_token
            this.$store.commit('setToken', token)

            axios.defaults.headers.common["Authorization"] = "Token " + token
            localStorage.setItem("token", token)
          })
          .catch(error => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors.push(`${property}: ${error.response.data[property]}`)
              }
              console.log(JSON.stringify(error.response.data))
            } else if (error.message) {
              console.log(JSON.stringify(error.message))
            } else {
              console.log(JSON.stringify(error))
            }
          })
      axios
          .get("/account/in/me/")
          .then(response => {
            this.$store.commit('setUser', {
              'username': response.data.username,
              'id': response.data.id,
              'uuid': response.data.uuid
            })
            localStorage.setItem('username', response.data.username)
            localStorage.setItem('userid', response.data.id)
            localStorage.setItem('uuid', response.data.uuid)
            if (response.data.uuid === null){
              this.$router.push('/final-step')
            } else {
              this.$router.push('/dashboard')
            }
          })
          .catch(error => {
            console.log(JSON.stringify(error))
          })
    }
  }
}
</script>