<template>
  <div class="home">
    <Navbar/>
    <div class="hero">
      <div class="flex flex-col md:text-center hero-content">
        <h1 class="mb-5 mt-5 text-4xl md:text-6xl tracking-wide leading-10 font-thin">
          Who else wants to command the game <span class="font-bold leading-loose text-purple-900">NOW?</span>
        </h1>
        <p class="mt-1 mb-3 px-5">
          Reach the best rank of the game with our automated analysis of your gameplay.<br>
          We analyze your games and provide you with insights and interactive heatmaps <br> to enable you to explore your
          play style and devise a meta of your own.
        </p>
        <div class="space-x-6 mb-3">
          <router-link v-if="$store.state.isAuthenticated" to="/dashboard" class="btn btn-primary">Continue to Dashboard</router-link>
          <router-link v-else to="/signup" class="btn btn-primary">Sign Up</router-link>
          <router-link to="/about" class="btn btn-outline">Learn More</router-link>
        </div>
        <div class="flex-none w-full md:w-8/12 mb-12">
          <div
              class="justify-center bg-base-100 l-img opacity-70 transition ease-in duration-700 hover:opacity-100">
            <img class="rounded-box" src="https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/generic/dashboard.png"
                 alt="landing-image">
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col mb-12 mt-6">
      <h1 class="text-4xl">
        So, what do we offer? 🎁
      </h1>
      <p class="mt-5">✋ First in the list is our promise to enable you be the best.
        We won't clutter your dashboard or your thoughts. Now, let's talk features.
      </p>
      <div class="flex flex-col space-y-4 md:space-y-0 md:space-x-4 mb-4 items-center justify-between md:flex-row mt-4">
        <div class="card bg-base-200">
          <div class="card-body">
            <div class="card-title">
              🚀 Matchwise Insights
            </div>
            <p>
              Raw insights to help you devise your own meta. <br>
              Each match is analyzed to provide you declutterred information
            </p></div>
        </div>
        <div class="card bg-base-200">
          <div class="card-body">
            <div class="card-title">
              📜 Historical Analysis
            </div>
            <p>
              We got you covered even here. <br>
              Your one stop to access the analysis of all your matches
            </p></div>
        </div>
        <div class="card bg-base-200">
          <div class="card-body">
            <div class="card-title">
              🔥 Heatmaps
            </div>
            <p>
              Analyse deaths, kills,
              and much more visually with our interactive heatmaps.<br>
              Plus, there are mapwise heatmaps too
            </p></div>
        </div>
      </div>
      <p class="text-center text-lg">Enough talking, don't you want to know whether you are the next TenZ or not? We do!</p>
      <router-link v-if="$store.state.isAuthenticated" to="/dashboard" class="btn btn-primary w-8/12 md:w-3/12 mt-5 self-center">Continue to Dashboard</router-link>
      <router-link v-else to="/signup" class="btn btn-primary w-5/12 md:w-3/12 mt-5 self-center">Sign Up Now</router-link>
    </div>


    <Footer/>
  </div>
</template>

<style scoped>

.l-img{
  border: #171618 solid 1px;
  border-radius: 50px;
  filter: drop-shadow(0 0 5px #3C167A);
}
</style>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Footer from "@/components/Footer";

export default {
  name: 'Home',
  components: {
    Footer,
    Navbar
  }
}
</script>
