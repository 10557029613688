<template>
  <Navbar />
  <div class="flex items-center flex-col">
    <p class="text-lg text-start mt-4 mb-4 leading-loose tracking-wide text-info">Sign Up</p>
    <ul class="steps steps-vertical md:steps-horizontal mt-5 mb-5">
      <li class="step step-primary">Sign Up</li>
      <li class="step">Connect Riot Account</li>
      <li class="step">Let's Go</li>
    </ul>
    <div>
      <div class="form-control">
        <form @submit.prevent="submitForm">
          <label class="input-group mb-4 mt-4">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg>
            </span>
            <input type="email" placeholder="Email" v-model="email" class="input input-bordered">
          </label>
          <label class="input-group mb-4 mt-4">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"/>
              </svg>
            </span>
            <input type="text" placeholder="Username" v-model="username" class="input input-bordered">
          </label>
          <label class="input-group mb-4 mt-4">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                      d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z"
                      clip-rule="evenodd"/>
              </svg>
            </span>
            <input type="password" placeholder="Password" v-model="password" class="input input-bordered">
          </label>

          <div class="mb-3 alert alert-error" v-if="errors.length" role="alert">
            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
          </div>
          <div class="mb-3 flex flex-wrap">
            <p class="text-start text-2xs text-gray-600">
              By signing up, you accept our <router-link to="/tos" class="text-info">terms of service</router-link>, <br>
              our <router-link to="/privacy-policy" class="text-info">privacy policy</router-link>, and our mailing list<br>
              (which you can unsubscribe later)
            </p>
          </div>
          <div class="mb-3">
            <button class="button btn btn-success mt-5">Sign Up</button>
          </div>
          <div class="mb-3">
            <p class="text-sm">Already have account? <router-link to="/login" class="text-secondary">Login</router-link> </p>
          </div>
        </form>
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios'
import Navbar from "@/components/Navbar";
import {onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";

export default {
  name: 'Signup',
  components: {Navbar},
  setup() {
    const username = ref('');
    const email = ref('');
    const password = ref('');
    const errors = ref([]);
    const router = useRouter();
    const route = useRoute();

    function submitForm() {
      const formData = {
        username: username.value,
        password: password.value,
        email: email.value
      };

      axios
          .post("/accounts/users/", formData)
          .then(response => {
            // console.log(response)
            router.push('/account/activation-pending')
          })
          .catch(error => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors.push(`${property}: ${error.response.data[property]}`)
              }
              console.log(JSON.stringify(error.response.data))
            } else if (error.message) {
              console.log(JSON.stringify(error.message))
            } else {
              console.log(JSON.stringify(error))
            }
          })
    };

    async function checkCode() {
      let code;
      code = route.query.ref;
      // console.log(typeof(code))
      if (code !== ''){
        await axios.post(
            '/account/in/ref-check/',
            {'referral_code': code}
        ).then(
            response => {
              if (response.data === 'Valid'){
                localStorage.setItem('ref', code)
              } else {
                alert('Code Invalid')
              }
            }
        )
      }
    }

    onMounted(() => {
      checkCode()
    })

    return{
      username,
      email,
      password,
      errors,
      submitForm
    }
  }
}
</script>