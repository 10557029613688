<template>

</template>

<script>

import {onMounted} from "vue";
import axios from "axios";
import {useRouter} from "vue-router";

export default {
  name: 'PaymentFail',
  setup() {
    const router = useRouter();
    onMounted(async () => {
      await axios.get(
          '/account/in/failed'
      )
      await router.push('/pricing')
    })
  }
}
</script>