<template>
  <div class="flex mt-2 flex-row space-x-6 justify-center">
    <Sidebar :menu="{
      'home': {'href': '/', 'title': 'Home'},
      'dash': {'href': '/dashboard', 'title': 'Dashboard'},
      'car': {'href': '/dashboard/career', 'title': 'Career'},
      'mapwise': {'href': '/dashboard/mapwise', 'title': 'Mapwise'},
      'profileCard': {'href': '/profile-card', 'title': 'Profile Card'},
      'spaces': {'href': '/spaces', 'title': 'Spaces'}
    }" v-if="!isHidden"/>
    <div class="flex-none w-3/5 items-start px-3 rounded-box">
      <div class="flex flex-row justify-between md:justify-end space-x-4 mt-6 items-end">
        <a class="md:hidden" @click="isHidden = !isHidden">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16"/>
          </svg>
        </a>
      </div>
      <div v-if="currentLobby !== null" class="overflow-x-auto flex-wrap w-full mt-6" style="height: 94vh">
        <div v-if="currChannelString !== null">
          <TwitchPlay v-if="channelType === true" :channel-name="currChannelString"/>
          <YouTubePlay v-if="channelType === false" :video-i-d="currChannelString"/>
        </div>
        <div v-else>
          <div>
            <p>No Channel</p>
          </div>
        </div>
        <div v-if="currentLobbyOwnership === 'Owned'" class="form-control">
          <div class="form-control flex flex-row items-center mt-2 mb-2 space-x-2">
            <label class="label">
              Youtube
            </label>
            <input v-model="channelType" type="checkbox" checked="checked" class="toggle">
            <label class="label">
              Twitch
            </label>
          </div>
          <div v-if="channelType === true" class="relative">
            <input type="text" placeholder="Twitch Channel Name" v-model="channelStringInput" @keyup.enter="setChannel"
                   class="w-full pr-16 input input-primary input-bordered">
            <button class="absolute top-0 right-0 rounded-l-none btn btn-primary" @click="setChannel">Enter</button>
          </div>
          <div v-if="channelType === false" class="relative">
            <input type="text" placeholder="Youtube Link" v-model="channelStringInput" @keyup.enter="setChannel"
                   class="w-full pr-16 input input-primary input-bordered">
            <button class="absolute top-0 right-0 rounded-l-none btn btn-primary" @click="setChannel">Enter</button>
          </div>
          <div>
            <p>Current Channel: {{ currChannelString }}</p>
          </div>
        </div>
        <div class="alert alert-warning py-1 mt-1">
          <div class="flex-1">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 class="w-6 h-6 mx-2 stroke-current">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
            </svg>
            <label>This is an experimental feature. Might be buggy. Please contact us to report any issues via
              Discord</label>
          </div>
        </div>
        <div v-if="currentLobby !== null" id="messagesDiv" class="overflow-y-auto h-60 mt-5 bg-base-200 rounded-box">
          <div class="flex m-2" v-for="messageA in messagesA">
            <div class="flex flex-col px-3 py-1 rounded-box bg-secondary" v-if="messageA['new'] === true">
              <span class="text-gray-300 capitalize">{{ messageA['sender'] }} </span> {{ messageA['message'] }}
            </div>
            <div class="flex flex-col px-3 py-1 rounded-box bg-primary" v-else>
              <span class="text-gray-300 capitalize">{{ messageA['sender'] }} </span> {{ messageA['message'] }}
            </div>
          </div>
        </div>
        <div v-if="ifWrite" class="form-control mt-5">
          <div class="relative">
            <input type="text" placeholder="Message" v-model="message" @keyup.enter="send"
                   class="w-full pr-16 input input-primary input-bordered" :maxlength="maxLength">
            <span class="absolute text-gray-600 top-3 right-20 rounded-l-none">{{ maxLength - message.length }}</span>
            <button class="absolute top-0 right-0 rounded-l-none btn btn-primary" @click="send">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"
                   style="transform: rotate(45deg)">
                <path
                    d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="flex items-center text-center" v-else>
        <p class="text-info">
          Please click on a space, create or join one
        </p>
      </div>
    </div>
    <div class="w-64 text-lg border-l-2 border-base-300 artboard hidden md:block">
      <div class="flex justify-between mt-6 ml-2 items-center">
        <div class="collapse w-96 border rounded-box border-base-100 bg-base-200">
          <input type="checkbox">
          <div class="collapse-title text-lg font-medium">
            Create your Space
          </div>
          <div class="collapse-content">
            <div class="form-control space-y-2">
              <input type="text" class="input" placeholder="Space Name" v-model="lobby_name">
              <input type="text" class="input" placeholder="Space Description" v-model="lobby_description">
              <select class="select" v-model="lobby_type">
                <option>Public</option>
                <option v-if="isPremium">Private</option>
                <option v-if="isPremium">Watchparty</option>
              </select>
              <input v-if="isPremium" type="text" class="input" placeholder="URI" v-model="lobby_uri">
              <button class="btn bg-green-400 hover:bg-green-700" @click="createLobby">Create</button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-between mt-6 ml-2 items-center">
        <div class="collapse w-96 border rounded-box border-base-100 bg-base-200 collapse-arrow">
          <input type="checkbox">
          <div class="collapse-title text-xl font-medium">
            My Spaces
          </div>
          <div class="collapse-content  h-72 overflow-y-auto">
            <div class="card mt-2 px-2" v-for="(v,k) in ownedLobbyList">
              <div v-if="activeLobby(k)"
                   class="flex flex-row py-2 bg-primary rounded-box px-2 justify-between items-center cursor-pointer"
                   @click="initiateSocket(v['uri']); getLobbyMembers(k, 'Owned')">
                <p class="text-sm">{{ v['name'] }}</p>
                <span class="badge badge-warning">Owned</span>
              </div>
              <div v-else
                   class="flex flex-row py-2 rounded-box px-2 bg-base-200 justify-between items-center cursor-pointer"
                   @click="initiateSocket(v['uri']); getLobbyMembers(k, 'Owned')">
                <p class="text-sm">{{ v['name'] }}</p>
                <span class="badge badge-warning">Owned</span>
              </div>
            </div>
            <div class="card mt-2 px-2" v-for="(v,k) in memberLobbyList">
              <div v-if="activeLobby(k)"
                   class="flex flex-row py-2 bg-primary rounded-box px-2 justify-between items-center cursor-pointer"
                   @click="initiateSocket(v['uri']); getLobbyMembers(k, 'Member')">
                <p class="text-sm">{{ v['name'] }}</p>
                <span class="badge badge-warning">Member</span>
              </div>
              <div v-else
                   class="flex flex-row py-2 rounded-box px-2 bg-base-200 justify-between items-center cursor-pointer"
                   @click="initiateSocket(v['uri']); getLobbyMembers(k, 'Member')">
                <p class="text-sm">{{ v['name'] }}</p>
                <span class="badge badge-warning">Member</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-between mt-1 ml-3 items-center">
        <button v-if="currentLobby !== null" @click="copyLobbyLink" class="btn btn-info">Invite Friends to Space</button>
      </div>
      <div class="flex justify-between mt-6 ml-2 items-center">
        <div class="collapse w-96 border rounded-box border-base-100 bg-base-200 collapse-arrow">
          <input type="checkbox">
          <div class="collapse-title text-xl font-medium">
            Space Members
          </div>
          <div class="collapse-content h-72 overflow-y-auto overflow-x-auto">
            <div class="flex flex-col rounded-box justify-between items-center bg-base-200 mt-2 px-2 py-1"
                 v-for="member in lobbyMembers">
              <p class="text-md self-start">
                {{ member[0] }}
              </p>
              <div class="flex flex-col self-start space-y-2 mt-1" v-if="currentLobbyOwnership === 'Owned'">
                <button @click="removeMember(member[0])"
                        class="bg-red-800 text-sm rounded-box px-3 hover:bg-red-600 pointer">
                  Remove Member
                </button>
                <button v-if="!member[1]" @click="writePermit(member[0], 'Add')" class="badge badge-primary">Allow Chat
                  Rights
                </button>
                <button v-else @click="writePermit(member[0], 'Remove')" class="badge badge-warning">Remove Chat
                  Rights
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {computed, nextTick, ref} from "vue";
import axios from "axios";
import {useStore} from "vuex";
import TwitchPlay from "@/components/TwitchPlay";
import YouTubePlay from "@/components/YouTubePlay";
import Sidebar from "@/components/Sidebar";

export default {
  name: 'LobbyRoom',
  components: {
    TwitchPlay,
    YouTubePlay,
    Sidebar
  },
  setup() {
    const store = useStore();

    // existing lobby vars
    const lobbyURI = ref(null);
    const status = ref(null);
    const message = ref('');
    const messagesA = ref(null);
    const ownedLobbyList = ref(null);
    const memberLobbyList = ref(null);
    const lobbyMembers = ref(null);
    const lobbyWriters = ref(null);
    const currentLobby = ref(null);
    const currLobbyOwnerName = ref(null);
    const currentLobbyOwnership = ref(null);
    const currentLobbyLink = ref(null);
    const ifWrite = ref(false);
    const currLobbyDetails = ref(null);
    const currLobbyUserLastLogin = ref(null);
    const currChannelString = ref(null);
    const channelStringInput = ref(null);
    const channelType = ref(false);
    const maxLength = ref(240);

    let lobbySocket = null;

    // new lobby vars
    const lobby_name = ref(null);
    const lobby_description = ref(null);
    const lobby_type = ref('Public');
    const lobby_uri = ref('');
    const isPremium = ref(false);

    const isHidden = ref(false);

    // Utility

    function activeLobby(lobby_uuid) {
      if (currentLobby.value === null) {
        return false
      } else {
        return currentLobby.value === lobby_uuid;
      }
    }

    function copyLobbyLink() {
      navigator.clipboard.writeText(currentLobbyLink.value);
      alert('Copied link to clipboard');
    }

    // new socket
    function newSocket(uri) {
      try {
        lobbySocket.close();
      } catch (error) {
        console.log(error);
      }
      // new websocket
      lobbyURI.value = uri;
      lobbySocket = new WebSocket(
          (window.location.protocol == 'https:' ? 'wss://' : 'ws://')
          + 'testapi.swiftskill.gg/lobby/chat/'
          + lobbyURI.value
          + '?token='
          + localStorage.getItem('token')
      )
      // `wss://testapi.swiftskill.gg/lobby/chat/${lobbyURI.value}?token=${localStorage.getItem('token')}`
      // user last login timestamp
      try {
        lastLoginFetch();
      } catch (error) {
        console.log(error);
      }

      // onmessage
      lobbySocket.onmessage = function (e) {
        let messageData = JSON.parse(e.data);
        // custom message triggers
        if (messageData.sender === currLobbyOwnerName.value) {
          const subString = messageData.message.split(' ');
          if (subString[0] === '**') {
            lobbyRefresh();
          } else if (subString[0] === '--') {
            lobbyRefresh();
            if (subString[1] === store.state.user.username) {
              initial();
              userKicked();
            }
          } else if (subString[0] === '%%' && store.state.user.username !== currLobbyOwnerName.value) {
            if (messageData.message.split('//')[1].split('==')[0] === 'twitch') {
              refreshChannel(true, messageData.message.split('//')[1].split('==')[1]);
            } else {
              refreshChannel(false, messageData.message.split('//')[1].split('==')[1]);
            }
          }
        }
        // New Member Joined
        if (messageData.sender === 'Server') {
          lobbyRefresh();
        }

        // push into existing messages
        messagesA.value.push(
            {
              'sender': messageData.sender,
              'message': messageData.message,
              'new': false
            }
        );
        nextTick(() => {
          let messagesDiv = document.getElementById('messagesDiv');
          messagesDiv.scrollTop = messagesDiv.lastElementChild.offsetTop;
        })
      }
    }

    // chat base functions

    function close() {
      lobbySocket.close();
    }

    function send(e) {
      if (['**', '--', '##', '%%', '//'].includes(message.value.split(' ')[0])) {
        alert(`Reserved starting charaters: ${message.value.split(' ')[0]}`);
        message.value = '';
      } else {
        lobbySocket.send(JSON.stringify({'message': message.value}));
        message.value = '';
      }
    }

    function notify(messageString) {
      lobbySocket.send(JSON.stringify({'message': messageString}));
    }

    // async

    async function initiateSocket(uri) {
      await axios.get(
          `/lobby/history/${uri}`
      ).then(
          response => {
            messagesA.value = response.data;
          }
      )
      newSocket(uri);
      currentLobbyLink.value = `https://swiftskill.gg/lobby/invite/${uri}`;
      let messagesDiv = document.getElementById('messagesDiv');
      messagesDiv.scrollTop = messagesDiv.lastElementChild.offsetTop;
      currChannelString.value = null;
    }

    async function initial() {
      await axios.get(
          '/lobby/'
      ).then(
          response => {
            ownedLobbyList.value = response.data['Owned'];
            memberLobbyList.value = response.data['Member'];
          }
      )
      // premium check
      await axios.get(
          '/account/in/p-checks/'
      ).then(
          response => {
            if (response.data === 'Premium') {
              isPremium.value = true;
            }
          }
      )
    }

    async function lobbyRefresh() {
      let csrf_token;
      await axios.get(
          '/valorant/csrf-token/'
      ).then(
          response => {
            csrf_token = response.data;
          }
      )

      await axios.post(
          '/lobby/lobby-refresh/',
          {
            headers: {
              'X-CSRFToken': csrf_token
            },
            'lobby_uuid': currentLobby.value
          }
      ).then(
          response => {
            currLobbyDetails.value = response.data;
            lobbyMembers.value = currLobbyDetails.value['members'];
            lobbyWriters.value = currLobbyDetails.value['writers'];
            ifWrite.value = hasWritePermit(currentLobbyOwnership.value);
          }
      )
    }

    async function lastLoginFetch() {
      let csrf_token;
      await axios.get(
          '/valorant/csrf-token/'
      ).then(
          response => {
            csrf_token = response.data;
          }
      )

      await axios.post(
          '/lobby/last-login/',
          {
            headers: {
              'X-CSRFToken': csrf_token
            },
            'lobby_uuid': currentLobby.value,
            'username': store.state.user.username
          }
      ).then(
          response => {
            currLobbyUserLastLogin.value = response.data['timestamp'];
          }
      )

      newMessageCheck();
    }

    async function writePermit(username, typeFlag) {
      let csrf_token;
      await axios.get(
          '/valorant/csrf-token/'
      ).then(
          response => {
            csrf_token = response.data;
          }
      )

      await axios.post(
          '/lobby/write-permissions/',
          {
            headers: {
              'X-CSRFToken': csrf_token
            },
            'status_flag': typeFlag,
            'lobby_uuid': currentLobby.value,
            'username': username
          }
      ).then(
          response => {
            if (response.data === 'Added') {
              notify(`** Write added for ${username} **`);
              alert('Member Added');
            } else {
              notify(`** Write removed for ${username} **`);
              alert('Member Removed');
            }
          }
      )
    }

    async function removeMember(username) {
      let csrf_token;
      await axios.get(
          '/valorant/csrf-token/'
      ).then(
          response => {
            csrf_token = response.data;
          }
      )

      await axios.post(
          '/lobby/remove-member/',
          {
            headers: {
              'X-CSRFToken': csrf_token
            },
            'lobby_uuid': currentLobby.value,
            'username': username
          }
      ).then(
          response => {
            if (response.data === 'Success') {
              // lobbyMembers.value = removeFromArray(lobbyMembers.value, username)
              notify(`-- ${username} kicked --`);
              alert('Member Removed');
            } else {
              alert('You are not the owner');
            }
          }
      )
    }

    async function createLobby() {
      // create api call
      let csrf_token;
      await axios.get(
          '/valorant/csrf-token/'
      ).then(
          response => {
            csrf_token = response.data;
          }
      )

      await axios.post(
          '/lobby/lobby-create/',
          {
            headers: {
              'X-CSRFToken': csrf_token
            },
            'lobby_uri': lobby_uri.value,
            'lobby_name': lobby_name.value,
            'lobby_description': lobby_description.value,
            'lobby_type': lobby_type.value,
          }
      ).then(
          response => {
            if (response.data === 'Success') {
              initial();
            } else {
              alert('Fookin mental!');
            }
          }
      )

    }

    // permissions/remove

    function newMessageCheck() {
      for (const chunk of messagesA.value) {
        if (chunk['timestamp'] >= currLobbyUserLastLogin.value && chunk['sender'] !== store.state.user.username) {
          chunk['new'] = true;
        }
        if (chunk['message'].split(' ')[0] === '%%') {
          if (chunk['message'].split('//')[1].split('==')[0] === 'twitch') {
            refreshChannel(true, chunk['message'].split('//')[1].split('==')[1]);
          } else {
            refreshChannel(false, chunk['message'].split('//')[1].split('==')[1]);
          }
        }
      }
    }

    function userKicked() {
      // close current lobby connection only for kicked user
      try {
        lobbySocket.close();
      } catch (error) {
        console.log(error);
      }
      // return user to default lobby view
      lobbyURI.value = null;
      message.value = '';
      messagesA.value = null;
      ifWrite.value = false;
      lobbyMembers.value = null;
      currLobbyOwnerName.value = null;
      currentLobbyOwnership.value = null;
      currentLobby.value = null;
      currLobbyDetails.value = null;
    }

    function hasWritePermit(ownership) {
      if (ownership === 'Owned') {
        return true
      } else {
        return lobbyWriters.value.includes(store.state.user.username)
      }
    }

    function getLobbyMembers(uuid, ownershipFlag) {
      currentLobby.value = uuid;
      currentLobbyOwnership.value = ownershipFlag;
      currLobbyOwnerName.value = ownershipFlag === 'Owned'
          ?
          ownedLobbyList.value[uuid]['owner']
          :
          memberLobbyList.value[uuid]['owner']
      lobbyMembers.value = ownershipFlag === 'Owned'
          ?
          ownedLobbyList.value[uuid]['members']
          :
          memberLobbyList.value[uuid]['members']

      lobbyWriters.value = ownershipFlag === 'Owned'
          ?
          ownedLobbyList.value[uuid]['writers']
          :
          memberLobbyList.value[uuid]['writers']

      ifWrite.value = hasWritePermit(currentLobbyOwnership.value);
    }

    function refreshChannel(cType, cString) {
      channelType.value = cType;
      currChannelString.value = cString;
    }

    function setChannel() {
      if (channelType.value === true) {
        currChannelString.value = channelStringInput.value;
        channelStringInput.value = null;
        notify(`%% channel switched to //twitch==${currChannelString.value}// %%`);
      } else {
        // if (channelStringInput.value.includes('watch?v=')) {
        //   currChannelString.value = channelStringInput.value.split('watch?v=')[1].split('&')[0];
        // } else {
        //   currChannelString.value = channelStringInput.value.split('/')[channelStringInput.value.split('/').length - 1];
        // }
        currChannelString.value = channelStringInput.value.split('watch?v=')[1].split('&')[0];
        channelStringInput.value = null;
        notify(`%% channel switched to //youtube==${currChannelString.value}// %%`);
      }
    }

    // calling initial at start
    initial();

    return {
      lobbyURI,
      close,
      send,
      newSocket,
      initiateSocket,
      getLobbyMembers,
      removeMember,
      writePermit,
      setChannel,
      currChannelString,
      channelStringInput,
      channelType,
      ifWrite,
      status,
      message,
      messagesA,
      ownedLobbyList,
      memberLobbyList,
      lobbyMembers,
      currentLobby,
      currentLobbyOwnership,
      currentLobbyLink,
      lobby_name,
      lobby_uri,
      lobby_description,
      lobby_type,
      createLobby,
      isPremium,
      isHidden,
      maxLength,
      activeLobby,
      copyLobbyLink,
    }
  }
}
</script>
