import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from "../views/Login";
import Signup from "@/views/Signup";
import AccountActivate from "@/views/AccountActivate";
import Dashboard from "@/views/Dashboard";
import MatchWise from "@/views/MatchWise";
import Mapwise from "@/views/Mapwise";
import Historical from "@/views/Historical";

import store from "@/store";
import ProfileCard from "@/views/ProfileCard";
import Pricing from "@/views/Pricing";
import PaymentSuccess from "@/views/PaymentSuccess";
import PaymentFail from "@/views/PaymentFail";
import PasswordReset from "@/views/PasswordReset";
import PasswordResetConfirm from "@/views/PasswordResetConfirm";
import AccountActivateInform from "@/views/AccountActivateInform";
import RiotInfo from "@/views/RiotInfo";
import LogoutSuccess from "@/views/LogoutSuccess";
import AccountSettings from "@/views/AccountSettings";
import TermsOfService from "@/views/TermsOfService";
import AffiliateTOS from "@/views/AffiliateTOS";
import PrivacyPolicy from "@/views/PrivacyPolicy";
import AffiliateInfo from "@/views/AffiliateInfo";
import PageNotFound from "@/views/PageNotFound";
import LobbyRoom from "@/views/LobbyRoom";
import LobbyMemberAdd from "@/views/LobbyMemberAdd";
import StreamerLanding from "@/views/StreamerLanding";



const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'SwiftSkill: Command the Game',
      description: 'Climb the Valorant ladder while exploring your true potential with SwiftSkill. SwiftSkill is a performance analysis tool for Valorant.'
    }
  },
  {
    path: '/partner',
    name: 'StreamerLanding',
    component: StreamerLanding,
    meta: {
      title: 'SwiftSkill: Command the Game',
      description: 'Climb the Valorant ladder while exploring your true potential with SwiftSkill. SwiftSkill is a performance analysis tool for Valorant.'
    }
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: 'SwiftSkill: Command the Game',
      description: 'Climb the Valorant ladder while exploring your true potential with SwiftSkill'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/not-found',
    name: 'PageNotFound',
    component: PageNotFound
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/not-found'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'SwiftSkill: Login',
      description: 'Login to SwiftSkill'
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    meta: {
      title: 'SwiftSkill: Sign Up',
      description: 'Get started with detailed analysis for Valorant and shine radiantly'
    }
  },
  {
    path: '/activate/:uid/:token',
    name: 'Activate',
    component: AccountActivate,
    meta: {
      title: 'SwiftSkill: Account Activation',
    }
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: PasswordReset,
    meta: {
      title: 'SwiftSkill: Password Reset',
    }
  },
  {
    path: '/password/reset/confirm/:uid/:token',
    name: 'PasswordResetConfirm',
    component: PasswordResetConfirm,
    meta: {
      title: 'SwiftSkill: Password Reset',
    }
  },
  {
    path: '/account/activation-pending',
    name: 'AccountActivateInform',
    component: AccountActivateInform
  },
  {
    path: '/final-step',
    name: 'RiotInfo',
    component: RiotInfo,
    meta: {
      requireLogin: true,
      title: 'SwiftSkill: One Last Step'
    }
  },
  {
    path: '/logout',
    name: 'LogoutSuccess',
    component: LogoutSuccess
  },
  {
    path: '/tos',
    name: 'TermsOfService',
    component: TermsOfService,
    meta: {
      title: 'SwiftSkill: Terms of Use',
    }
  },
  {
    path: '/affiliates',
    name: 'AffiliateInfo',
    component: AffiliateInfo,
    meta: {
      title: 'SwiftSkill: Affiliate Program',
    }
  },
  {
    path: '/affiliate-tos',
    name: 'AffiliateTOS',
    component: AffiliateTOS,
    meta: {
      title: 'SwiftSkill: Affiliate Policy',
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {
      title: 'SwiftSkill: Privacy Policy',
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requireLogin: true,
      title: 'SwiftSkill: Matchwise Analysis & Insights',
      description: 'Assess your performance on a match by match basis'
    }
  },
  {
    path: '/dashboard/match/:matchid',
    name: 'Matchwise',
    component: MatchWise,
    props: true,
    meta: {
      requireLogin: true,
      title: 'SwiftSkill: Matchwise Analysis & Insights',
      description: 'Assess your performance on a match by match basis'
    }
  },
  {
    path: '/dashboard/mapwise',
    name: 'Mapwise',
    component: Mapwise,
    props: true,
    meta: {
      requireLogin: true,
      title: 'SwiftSkill: Matchwise Analysis & Insights',
      description: 'Assess your performance on an individual map'
    }
  },
  {
    path: '/dashboard/career',
    name: 'Historical',
    component: Historical,
    props: true,
    meta: {
      requireLogin: true,
      title: 'SwiftSkill: Matchwise Analysis & Insights',
      description: 'Assess your career'
    }
  },
  {
    path: '/profile-card',
    name: 'ProfileCard',
    component: ProfileCard,
    meta: {
      requireLogin: true,
      title: 'SwiftSkill: Matchwise Analysis & Insights',
      description: 'Assess your career'
    }
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing,
    meta: {
      requireLogin: true,
      title: 'SwiftSkill: Matchwise Analysis & Insights',
      description: 'Unlock your radiance with SwiftSkill Premium'
    }
  },
  {
    path: '/payment/stripe-success/:session_id',
    name: 'PaymentSuccess',
    component: PaymentSuccess,
    meta: {
      requireLogin: true,
      title: 'SwiftSkill: Success',
      description: 'Unlock your radiance with SwiftSkill Premium'
    }
  },
  {
    path: '/payment/failed',
    name: 'PaymentFail',
    component: PaymentFail,
    meta: {
      requireLogin: true,
      title: 'SwiftSkill: Failed',
      description: 'Unlock your radiance with SwiftSkill Premium'
    }
  },
  {
    path: '/account-settings',
    name: 'AccountSettings',
    component: AccountSettings,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/spaces',
    name: 'LobbyRoom',
    component: LobbyRoom,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/space/invite/:lobby_uri',
    name: 'LobbyMemberAdd',
    component: LobbyMemberAdd,
    meta: {
      requireLogin: true
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: "border-l-4 border-primary"
})

// Add auth for Premium and add store variable for the same
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next('/login')
  } else {
    next()
  }
  if(to.meta.title && to.meta.description){
    document.title = to.meta.title;
    document.querySelector('meta[name="description"]').setAttribute("content", `${ to.meta.description}`);
  }
})


export default router
