<template>

</template>

<script>

import {useRoute, useRouter} from "vue-router";
import {onMounted} from "vue";
import axios from "axios";

export default {
  name: 'PaymentSuccess',
  setup() {
    const route = useRoute();
    const router = useRouter();

    const session_id = route.params.session_id;

    onMounted(async () => {
      await axios.get(`/account/in/stripe-success/${session_id}`)

      await axios.get(
          '/account/in/subscription-confirm/'
      ).then(
          () => {
            router.push('/dashboard')
          }
      )
    })
  }
}

</script>