<template>

  <div class="navbar mb-2 shadow-lg bg-base-100 text-neutral-content rounded-box">
    <div class="flex-none px-2 mx-2">
      <img src="https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/generic/Logo.png">
    </div>
    <div class="flex-1">
      <div class="items-stretch" v-for="menuItem in menu">
        <router-link :to="menuItem.href" class="btn btn-ghost btn-sm rounded-btn items-center">
          {{menuItem.title}}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavbarMob',
  props: {
    'menu': Object,
  }
}
</script>