<template>
  <Navbar />
  <div class="flex items-center justify-center">
    <div class="px-4 lg:py-12">
      <div class="lg:gap-4 lg:flex">
        <div
            class="flex flex-col items-center justify-center md:py-24 lg:py-32"
        >
          <h1 class="font-bold text-blue-600 text-9xl">404</h1>
          <p
              class="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl"
          >
            <span class="text-red-500">Oops!</span> Page not found
          </p>
          <p class="mb-8 text-center text-gray-500 md:text-lg">
            The page you’re looking for doesn’t exist.
          </p>
          <router-link
              to="/"
              class="btn btn-primary"
          >Go home
          </router-link
          >
        </div>
        <div class="mt-4">
          <img
              src="https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/generic/404.svg"
              alt="404Image"
              class="object-cover w-full h-full"
          />
        </div>
      </div>
    </div>
  </div>

  <Footer />

</template>

<script>

import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
export default {
  name: 'PageNotFound',
  components: {Footer, Navbar},
}
</script>