<template>

  <div class="fixed top-0 right-0 left-0 bottom-0 w-full h-screen z-50 overflow-hidden opacity-75 flex flex-col items-center justify-center bg-base-200">
    <div class="w-48 flex items-center">
      <svg version="1.1" id="L5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
           y="0px"
           viewBox="0 0 200 200" enable-background="new 0 0 0 0" xml:space="preserve">
      <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
        <animateTransform
            attributeName="transform"
            dur="1s"
            type="translate"
            values="0 15 ; 0 -15; 0 15"
            repeatCount="indefinite"
            begin="0.1"/>
      </circle>
        <circle fill="#fff" stroke="none" cx="30" cy="50" r="6">
        <animateTransform
            attributeName="transform"
            dur="1s"
            type="translate"
            values="0 10 ; 0 -10; 0 10"
            repeatCount="indefinite"
            begin="0.2"/>
      </circle>
        <circle fill="#fff" stroke="none" cx="54" cy="50" r="6">
        <animateTransform
            attributeName="transform"
            dur="1s"
            type="translate"
            values="0 5 ; 0 -5; 0 5"
            repeatCount="indefinite"
            begin="0.3"/>
      </circle>
    </svg>
    </div>
  </div>

</template>

<script>

export default {
  name: 'FullScreenLoader'
}

</script>