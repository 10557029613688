<template>
  <div class="flex justify-center">
    <canvas ref="heatmapCnv"></canvas>
  </div>
</template>

<script>

import {onUpdated, ref} from "vue";

export default {
  name: 'MapwiseKD',
  props: {
    chartType: String,
    pointData: Object,
    mapname: String,
  },
  setup(props) {
    const heatmapCnv = ref(null);

    const cdnurl = 'https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets';
    const csize = 600;
    const prad = 6;

    function initial() {
      try {
        const heatmapcanvas = heatmapCnv.value;
        const heatmapCtx = heatmapcanvas.getContext('2d');

        heatmapcanvas.width = csize;
        heatmapcanvas.height = csize;
        heatmapcanvas.style.backgroundImage = 'url(' + cdnurl + `/maps-general/${props.mapname}.png)`;
        heatmapcanvas.style.backgroundRepeat = "no-repeat";
        heatmapcanvas.style.backgroundSize = "100%";

        default_chart(heatmapCtx, heatmapcanvas, props.pointData, props.chartType);
      } catch (err) {
        console.log(err)
      }
    }

    function default_chart(ctx, canvas, data, type) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      const colormap = {
        'kill': "rgba(57, 255, 20, 0.3)",
        'death': "rgba(220, 20, 60, 0.3)"
      };
      if (type === 'kill') {
        for (let obj of data) {
          drawOnCanvas(ctx, obj[0], obj[1], colormap[type])
        }
      } else {
        for (let obj of data) {
          drawOnCanvas(ctx, obj[0], obj[1], colormap[type])
        }
      }
    }

    function drawOnCanvas(ctx, xCord, yCord, color) {
      const point = new Path2D();
      let x = xCord / 1024 * csize;
      let y = yCord / 1024 * csize;

      point.arc(x, csize - y, prad, 0, 2 * Math.PI);

      ctx.fillStyle = color;
      ctx.shadowBlur = 3;
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 0;
      ctx.shadowColor = "White";
      ctx.fill(point);
    }


    onUpdated(() => {
      initial();
    })

    return {
      heatmapCnv
    }
  }
}
</script>