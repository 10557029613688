<template>

</template>

<script>

import {useRoute, useRouter} from "vue-router";
import axios from "axios";

export default {
  name: 'LobbyMemberAdd',
  setup(){

    const route = useRoute();
    const router = useRouter();

    async function initial(){
      let csrf_token;
      await axios.get(
          '/valorant/csrf-token'
      ).then(
          response => {
            csrf_token = response.data
          }
      )

      await axios.post(
          `/lobby/add-member/${route.params.lobby_uri}/`
      ).then(
          response => {
            if (response.data === 'OK' || response.data === 'Already In'){
              router.push('/spaces');
            } else if (response.data === 'Full') {
              // push somewhere else
              alert('Space is now full. You will be redirected to Dashboard.');
              router.push('/dashboard')
            } else {
              alert('Invalid Invite');
              router.push('/dashboard')
            }
          }
      )
    }

    initial()
  }
}
</script>