<template>
  <Navbar />
  <div class="flex items-center flex-col">
    <div>
      <div class="form-control">
        <p class="text-lg text-start mt-4 mb-4 leading-loose tracking-wide text-info">Password Reset</p>
        <form @submit.prevent="completeProcess">
          <label class="input-group mb-4 mt-4">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                      d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z"
                      clip-rule="evenodd"/>
              </svg>
            </span>
            <input type="password" placeholder="New Password" v-model="password" class="input input-bordered">
          </label>
          <div class="mb-3">
            <button class="button btn btn-success">Reset Password</button>
          </div>
        </form>
        <div ref="responseDiv"></div>
      </div>
    </div>
  </div>

</template>

<script>
import Navbar from "@/components/Navbar";
import {useRoute, useRouter} from "vue-router";
import axios from "axios";
import { ref} from "vue";
export default {
  name: 'PasswordResetConfirm',
  components: {Navbar},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const password = ref('');

    async function completeProcess(){
      let token = route.params.token;
      let uid = route.params.uid;
      // console.log(token, uid)
      await axios.post(
          '/accounts/users/reset_password_confirm/',
          {
            headers: {'Content-type': 'application/json'},
            'token': token,
            'uid': uid,
            'new_password': password.value
          }
      ).then(
          response => {
            if (response.status === 204){
              router.push('/login')
            } else {
              alert('Some Error Occurred. Please contact if it persists')
            }
          }
      ).catch(
          error => {
            console.log(error)
          }
      )
    };

    return {
      completeProcess,
      password,
    }
  }
}
</script>