<template>
<div class="flex mt-2 flex-row space-x-6 justify-center">
    <Sidebar :menu="{
      'home': {'href': '/', 'title': 'Home'},
      'dash': {'href': '/dashboard', 'title': 'Dashboard'},
      'car': {'href': '/dashboard/career', 'title': 'Career'},
      'mapwise': {'href': '/dashboard/mapwise', 'title': 'Mapwise'},
      'profileCard': {'href': '/profile-card', 'title': 'Profile Card'},
    }" v-if="!isHidden" />
    <div class="flex-none w-3/5 items-start px-3 rounded-box">
      <div class="flex flex-row justify-between md:justify-end space-x-4 mt-6 items-end">
        <a class="md:hidden" @click="isHidden = !isHidden">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16"/>
          </svg>
        </a>
      </div>
      <div class="overflow-x-auto flex-wrap justify-between w-full mt-6" style="height: 95vh">
        <div class="px-3 py-3 bg-base-200 rounded-box">
          <div class="flex flex-col justify-betweem items-center">
            <p class="text-secondary text-2xl uppercase leading-loose tracking-wide">{{username}}'s Account</p>
            <div class="stat rounded-box mt-5">
              <div v-if="isPremium">
                <div class="stat-title">Active Plan</div>
                <div class="stat-value mb-4">Premium</div>
                <button class="btn btn-warning" @click="cancelSub">Cancel Subscription</button>
              </div>
              <div v-else>
                <div class="stat-title">Active Plan</div>
                <div class="stat-value">Free</div>
              </div>
            </div>
            <div class="stat rounded-box mt-5">
              <div v-if="isAffiliate">
                <div class="stat-title">Affiliate Status</div>
                <div class="stat-value">Active</div>
                <table class="table mt-10 mb-10">
                  <thead>
                  <tr>
                    <th>Free</th>
                    <th>Monthly</th>
                    <th>Quarterly</th>
                    <th>Yearly</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{{accDump.free_users}}</td>
                    <td>{{accDump.monthly_subs}}</td>
                    <td>{{accDump.quarterly_subs}}</td>
                    <td>{{accDump.yearly_subs}}</td>
                  </tr>
                  </tbody>
                </table>
                <div class="flex items-center space-x-4">
                  <div>
                    Your Referral Code is <span class="text-xl text-info ml-1">{{ accDump.refCode }}</span>
                  </div>
                  <div>
                    <button @click="copyLink" class="ml-2 btn btn-info">
                      <div class="mr-4">Copy Link</div>
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                           stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"/>
                      </svg>
                    </button>
                  </div>
                </div>

              </div>
              <div class="flex flex-col space-y-4" v-else>
                <div class="stat-title">Affiliate Status</div>
                <div class="stat-value">Inactive</div>
                <button class="btn btn-primary" @click="affiliateReq" v-if="!affiliatePending">Join</button>
                <button class="btn disabled" disabled v-else>Request Pending</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-64 text-lg border-l-2 border-base-300 artboard hidden md:block">
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {onMounted, ref} from "vue";
import Sidebar from "@/components/Sidebar";
import {useRouter} from "vue-router";

export default {
  name: 'AccountSettings',
  components: {
    Sidebar
  },
  setup() {
    let csrf_token;
    const accDump = ref({});
    const username = localStorage.getItem('username');
    const isPremium = ref(false);
    const isAffiliate = ref(false);
    const affiliatePending = ref(false);
    const isHidden = ref(false);
    const router = useRouter();

    async function initial(){
      await axios.get(
          '/valorant/csrf-token/'
      ).then(
          response => {
            csrf_token = response.data
          }
      ).catch(
          error => {
            if (error.response.status === 401) {
              router.push('/login')
            }
          }
      )

      await axios.post(
          '/account/in/account-gate/',
          {
            headers: {'X-CSRFToken': csrf_token}
          }
      ).then(
          response => {
            if (response.data !== 'Non-Premium'){
              accDump.value = response.data;
              isAffiliate.value = true;
            }
          }
      )

      await axios.get(
          '/account/in/p-checks/'
      ).then(
          response => {
            if (response.data === 'Premium'){
              isPremium.value = true
            }
          }
      )
    }

    async function affiliateReq(){
      await axios.get(
          '/valorant/csrf-token/'
      ).then(
          response => {
            csrf_token = response.data
          }
      )

      await axios.post(
          '/account/in/affiliate-gate/',
          {headers: {
            'X-CSRFToken': csrf_token
            }
          }
      ).then(
          response => {
            if (response.data === 'Requested'){
              affiliatePending.value = true
            } else {
              alert('Some error occurred during processing your request. Please contact us if the issue persists. It could be due to your previous pending requests')
            }
          }
      )
    }

    function copyLink(){
      let copytext = `https://swiftskill.gg/signup?ref=${accDump.value.refCode}`;
      //console.log(copytext)
      navigator.clipboard.writeText(copytext)

      alert('Copied link to clipboard')
    }

    async function cancelSub(){
      await axios.get(
          '/account/in/stripesub-cancel-end'
      ).then(
          response => {
            if (response.data === 'Subscription Cancelled'){
              alert('We are sorry you intend to discontinue your premium status. One of our team member would get in touch to ensure the process is smooth.')
            }
          }
      )
    }

    onMounted(() => {
      initial()
    })

    return {
      username,
      accDump,
      isPremium,
      isAffiliate,
      affiliateReq,
      affiliatePending,
      copyLink,
      cancelSub,
      isHidden
    }
  }
}
</script>