<template>
  <Navbar />

  <div class="flex flex-row md:flex-col justify-center space-y-8 space-x-2 px-4 mt-10 mb-8">
    <div class="card lg:card-side">
      <div class="card-body md:w-2/5">
        <div class="card-title">
          <span v-if="$store.state.user.username" class="capitalize text-8xl leading-10 tracking-wide">
            Hi, {{$store.state.user.username}}! 👋
          </span>
          <span class="text-8xl" v-else>
            Hi, there! 👋
          </span>
        </div>
        <div>
          <p>
            With our affiliate program we intend to reach out to larger community of gamers.
            The motive is simple - instead of us paying money to Zukerberg or Google, we pay our affiliates.
          </p>
          <p class="mt-5">
            The process is relatively straightforward: once you buy a premium plan, yes you would have to, you will be
            able to visit <span class="text-secondary">Account Settings</span> and click on <span
              class="text-secondary">JOIN</span>
            button. We, then, process your request and get back to you with the details and procedures via email.
          </p>
          <p class="mt-5">
            Feel free to reach out to us at our Discord if you have any questions or concerns
          </p>
        </div>
      </div>
      <div class="mt-5 mb-5 px-5">
        <img class="w-full"  src="https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/generic/giving_back.svg">
      </div>
    </div>

  </div>

  <Footer />
</template>

<script>

import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
export default {
  name: 'AffiliateInfo',
  components: {Footer, Navbar},
}
</script>