<template>
  <div class="flex flex-row justify-between">
    <div class="px-4 flex flex-col">
      <canvas ref="deathsnapshot"></canvas>
      <label class="label mt-5 mb-1">Round Slider</label>
      <input ref="roundSlider" @change="sliderChange" type="range" :max="totalRounds" value="0"
             class="w-2/5 mt-1 mb-4 range range-primary">
      <span class="py-4 text-xl text-info">
        Round {{ sliderVal }} <span class="text-white">won by</span> <span class="text-secondary">{{ rSnippet['winningteam'] }}</span><br><br>
        <span class="text-info">Win Reason</span> <span class="text-white">{{rSnippet['endreason']}}</span>
      </span>
    </div>
    <div class="flex flex-col px-4 space-y-4">
      <div class="flex items-center" v-for="snip in rSnippet['summary']">
        <div class="badge badge-info py-4 mt-2 mb-2 mr-4">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-3" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                  clip-rule="evenodd"/>
          </svg>
          {{ processtime(snip[0]) }}
        </div>
        <div class="flex items-center space-x-4" v-if="snip[1]['eventtype'] === 'kill'">
          <span v-if="agentImg(snip[1]['details']['killer'], 'bg') === 'Enemy'">
            <img class="bg-red-500 rounded-box" :src="agentImg(snip[1]['details']['killer'], 'path')" style="width: 35px;">
          </span>
          <span v-else>
            <img class="bg-green-500 rounded-box" :src="agentImg(snip[1]['details']['killer'], 'path')" style="width: 35px;">
          </span>

          <img :src="weaponImg(snip[1]['details']['weapon'])" style="max-height: 256px; max-width: 96px; width: 30%; height: 40%; transform: scaleX(-1)">

          <span v-if="agentImg(snip[1]['details']['victim'], 'bg') === 'Enemy'">
            <img class="bg-red-500 rounded-box" :src="agentImg(snip[1]['details']['victim'], 'path')" style="width: 35px;">
          </span>
          <span v-else>
            <img class="bg-green-500 rounded-box" :src="agentImg(snip[1]['details']['victim'], 'path')" style="width: 35px;">
          </span>
        </div>
        <div class="flex items-center space-x-4" v-else-if="snip[1]['eventtype'] === 'bombplant'">
          <span v-if="agentImg(snip[1]['details']['planter'], 'bg') === 'Enemy'">
            <img class="bg-red-500 rounded-box" :src="agentImg(snip[1]['details']['planter'], 'path')" style="width: 35px;">
          </span>
          <span v-else>
            <img class="bg-green-500 rounded-box" :src="agentImg(snip[1]['details']['planter'], 'path')" style="width: 35px;">
          </span>
          <span class="ml-4">Planted Spike on {{snip[1]['details']['site']}} Site</span>
        </div>
        <div class="flex items-center space-x-4" v-else>
          <span v-if="agentImg(snip[1]['details']['defuser'], 'bg') === 'Enemy'">
            <img class="bg-red-500 rounded-box" :src="agentImg(snip[1]['details']['defuser'], 'path')" style="width: 35px;">
          </span>
          <span v-else>
            <img class="bg-green-500 rounded-box" :src="agentImg(snip[1]['details']['defuser'], 'path')" style="width: 35px;">
          </span> <span class="ml-4">Defused Spike</span>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import {onUpdated, ref} from "vue";

export default {
  name: 'RoundSnippet',
  props: {
    mapname: String,
    premium: Boolean,
    oldData: Boolean,
    dsData: Object,
    totalRounds: Number,
    snippet: Object
  },
  setup(props) {
    const cdnurl = 'https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets';
    const deathsnapshot = ref(null);
    let ds_canvas = null;
    let ds_ctx = null;
    let rSnippet = ref({});
    let snippet = null;
    let ds_data = null;
    let allkeys = null;
    let mapname = null;

    let roundSlider = ref(null);
    let sliderVal = ref('0');

    const csize = 400;

    let currkey = '0';

    function initial() {
      if (props.premium === true) {
        snippet = props.snippet;
        ds_data = props.dsData;
        allkeys = Object.keys(ds_data);
        mapname = props.mapname;

        ds_canvas = deathsnapshot.value;
        ds_ctx = ds_canvas.getContext('2d');
        ds_canvas.width = csize;
        ds_canvas.height = csize;
        ds_canvas.style.backgroundImage = 'url(' + cdnurl + `/maps-general/${mapname}.png)`;
        ds_canvas.style.backgroundRepeat = "no-repeat";
        ds_canvas.style.backgroundSize = "100%";
      }
      sliderChange();
    }

    function processtime(timeval) {
      let timer
      let time = Math.round(timeval / 1000)
      if (time < 60) {
        if (time < 10) {
          timer = '0:0' + String(time)
        } else {
          timer = '0:' + String(time)
        }
      } else if (time === 60) {
        timer = '1:00'
      } else if (time > 60 && time < 120) {
        if (time - 60 < 10) {
          timer = '1:0' + String(time - 60)
        } else {
          timer = '1:' + String(time - 60)
        }
      } else if (time === 120) {
        timer = '2:00'
      } else {
        if (time - 120 < 10) {
          timer = '2:0' + String(time - 120)
        } else {
          timer = '2:' + String(time - 120)
        }
      }
      return timer
    }

    function agentImg(val, flag) {
      return flag === 'path'
          ?
          `https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/agents/${val.includes("Enemy")
              ?
              val.split(' ')[1]
              :
              val}.png`
          :
          val.includes("Enemy") ? "Enemy" : "Team"
    }

    function weaponImg(val) {
      return val.includes("Ultimate") || val.includes("Ability")
          ?
          `https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/agent-abilities/${val}`
          :
          `https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/weapons/${val}`
    }

    function sliderChange() {
      sliderVal.value = roundSlider.value.value;
      rSnippet.value = snippet[roundSlider.value.value];
      setkey(roundSlider.value.value);
      show_deathsnap();
    }

    function setkey(key) {
      currkey = key;
    }

    function show_deathsnap() {
      if (allkeys.includes(currkey) === true) {
        default_dschart(ds_ctx, ds_canvas, ds_data[currkey], "on", "on");
      } else {
        // Add code for no death this round
        ds_ctx.clearRect(0, 0, ds_canvas.width, ds_canvas.height);
      }
    }

    function default_dschart(ctx, canvas, data, enemyval, teamval) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      let vertices = [];

      let killerx = null;
      let killery = null;
      let victimx = null;
      let victimy = null;

      for (let element of data) {
        let x = element[0];
        let y = element[1];
        let agent = element[3];
        let kind = element[4];

        if (kind === 'Killer') {
          killerx = x;
          killery = y;
        } else if (kind === 'Victim') {
          victimx = x;
          victimy = y;
        }
        if (kind === 'Teammate') {
          if (teamval === "on") {
            drawOnCanvas_ds(ctx, canvas, x, y, agent, kind)
          }
        } else if (kind === 'Enemy') {
          if (enemyval === "on") {
            drawOnCanvas_ds(ctx, canvas, x, y, agent, kind)
          }
        } else {
          drawOnCanvas_ds(ctx, canvas, x, y, agent, kind)
        }
      }
      drawLineEnemies_ds(ctx, canvas, killerx, killery, victimx, victimy, vertices)
      animateIt_ds(ctx, canvas, vertices);
    }

    function drawLineEnemies_ds(ctx, canvas, x1, y1, x2, y2, vertices) {
      x1 = x1 / 1024 * csize;
      y1 = y1 / 1024 * csize;
      x2 = x2 / 1024 * csize;
      y2 = y2 / 1024 * csize;

      ctx.beginPath();
      ctx.setLineDash([8, 12]);
      ctx.moveTo(x1, csize - y1);
      ctx.lineTo(x2, csize - y2);
      ctx.lineWidth = 1;
      ctx.strokeStyle = "#FFFAF0"
      ctx.stroke();

      //var vertices = [];
      vertices.push({
        x: x1,
        y: csize - y1
      });
      vertices.push({
        x: x2,
        y: csize - y2
      });
    }

    function animateIt_ds(ctx, canvas, vertices) {
      function animate() {
        if (t < points.length - 1) {
          requestAnimationFrame(animate);
        }
        ctx.beginPath();
        ctx.moveTo(points[t - 1].x, points[t - 1].y);
        ctx.lineTo(points[t].x, points[t].y);
        ctx.stroke();
        t++;
      }

      let t = 1;
      ctx.lineWidth = 3;
      ctx.strokeStyle = "#FFFAF0";
      let points = calcWaypoints_ds(vertices);
      animate(points);
    }

    function calcWaypoints_ds(vertices) {
      let waypoints = [];
      for (let i = 1; i < vertices.length; i++) {
        let pt0 = vertices[i - 1];
        let pt1 = vertices[i];
        let dx = pt1.x - pt0.x;
        let dy = pt1.y - pt0.y;
        for (let j = 0; j < 100; j++) {
          let x = pt0.x + dx * j / 100;
          let y = pt0.y + dy * j / 100;
          waypoints.push({
            x: x,
            y: y
          });
        }
      }
      return (waypoints);
    }

    function drawOnCanvas_ds(ctx, canvas, xCord, yCord, agent, kind) {
      const colormap = {
        "Killer": "#DC143C",
        "Victim": "#00FF00",
        "Teammate": "#00FF00",
        "Enemy": "#DC143C"
      }
      let x = xCord / 1024 * csize;
      let y = yCord / 1024 * csize;
      let nImg = new Image();
      nImg.onload = function () {
        let point = new Path2D();
        ctx.fillStyle = colormap[kind];
        point.arc(x, csize - y, 15, 0, 2 * Math.PI);
        ctx.shadowBlur = 30;
        ctx.shadowColor = colormap[kind];
        ctx.fill(point);
        ctx.drawImage(nImg, x - 12, csize - y - 12, 25, 25);
      }
      nImg.src = cdnurl + `/agents/${agent}.png`;
    }

    onUpdated(() => {
      initial();
    })

    return {
      deathsnapshot,
      rSnippet,
      processtime,
      roundSlider,
      sliderChange,
      sliderVal,
      weaponImg,
      agentImg,
    }
  }
}
</script>